import React from 'react'
import { connect } from "react-redux";
import { ReactComponent as SampleProfileAvatar } from '../assets/avatar.svg';
import { s3UserImageUrl } from '../config';

function UserProfileView({ userDetails, profile }) {
    return (
        <>
            <div className="text-left border-b-2 border-deactivated p-6">
                <div className="shrink-0">
                    {
                        profile?.updatedProfileImg ? <>
                            <img className='rounded-full w-20 h-20' src={profile?.updatedProfileImg} alt={"profile"} />
                        </> : <>
                            {userDetails?.userData?.imgData ?
                                <img className='rounded-full w-20 h-20' src={`${s3UserImageUrl}/${userDetails?.userData?.imgData}`} alt={"profile"} /> : <SampleProfileAvatar className="w-20" fill='#3c3c3c' />}
                        </>
                    }
                </div>
                <div>
                    <div className="text-xl text-black">{ profile?.fullName || "NA" }</div>
                    <p className="text-slate-500">{ userDetails?.companyName }</p>
                    <p className="text-slate-500 ">{ userDetails?.userData?.userTypeId?.name || userDetails?.userData?.permissions }</p>
                </div>
            </div>

        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.auth.userDetails,
    profile: state.profile
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileView);