export const assetsTypes = {
    ASSET_TYPE_LIST_LOADING: "ASSET_TYPE_LIST_LOADING",
    ASSET_TYPE_LIST_SUCCESS: "ASSET_TYPE_LIST_SUCCESS",
    ASSET_TYPE_LIST_ERROR: "ASSET_TYPE_LIST_ERROR",
    RESET_ASSET_TYPE_LIST_STATE: "RESET_ASSET_TYPE_LIST_STATE",
    ASSET_LIST_LOADING: "ASSET_LIST_LOADING",
    ASSET_LIST_SUCCESS: "ASSET_LIST_SUCCESS",
    ASSET_LIST_ERROR: "ASSET_LIST_ERROR",
    RESET_ASSET_LIST_STATE: "RESET_ASSET_LIST_STATE",
    GET_ASSET_CHECKLIST_LOADING: "GET_ASSET_CHECKLIST_LOADING",
    GET_ASSET_CHECKLIST_SUCCESS: "GET_ASSET_CHECKLIST_SUCCESS",
    GET_ASSET_CHECKLIST_ERROR: "GET_ASSET_CHECKLIST_ERROR",
    RESET_ASSET_CHECKLIST_STATE: "RESET_ASSET_CHECKLIST_STATE"
}