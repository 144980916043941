import { combineReducers } from "redux";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import assetsReducer from "./assets";
import authReducer from "./auth";
import inspectReducer from "./inspection";
import profileReducer from "./profile"

// Session Storage for current user
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth","profile"],
};

const appReducer = combineReducers({
  auth: authReducer,
  assets: assetsReducer,
  inspect: inspectReducer,
  profile: profileReducer
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
