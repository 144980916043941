import React, { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { useNavigate } from "react-router-dom";
import { ReactComponent as SelectIcon } from '../../assets/ArrowRight.svg';
import { useTranslation } from 'react-i18next'
import { Trans } from 'react-i18next';
const languages = [
    { name: 'ENGLISH', value: "en" },
    { name: 'SPANISH', value: "es" },
]

function LanguageSelection() {

    const { t, i18n } = useTranslation()

    let navigate = useNavigate();
    
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)
    const [isLanguageChanged, setIsLanguageChanged] = useState(false)

    useEffect(() => {
        if(selectedLanguage!==i18n.language){
            setIsLanguageChanged(true)
        }else{
            setIsLanguageChanged(false)
        }
    }, [selectedLanguage])

    const handleBackButton = () => {
        navigate("/asset-list", { replace: true })
    }

    const handleLanguageChange = (e) => {
        console.log(e.target.value)
        setSelectedLanguage(e.target.value)
    }

    const handleUpdateButton = () => {
        i18n.changeLanguage(selectedLanguage)
        setIsLanguageChanged(false)
    }


    return (
        <>
            <div className='w-full bg-primary-background p-3 text-white font-light text-lg flex items-center font-kanit drop-shadow-[0_2px_4px_rgba(0,0,0,0.65)]'>
                <span className='mr-1 cursor-pointer' onClick={() => handleBackButton()}>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
                    </svg>
                </span>
                <p><Trans>Language Selection</Trans></p>
            </div>

            <div className='mx-auto w-4/5 sm:w-6/12 md:w-1/2 lg:w-3/12 h-[70vh] font-sans mt-5 flex flex-col justify-between'>
                <div>
                    <label for="language" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"><Trans>Select your preferred language</Trans></label>
                    <select value={selectedLanguage} id="language" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={(e) => handleLanguageChange(e)}>
                        { languages?.map((language) => {
                            return (
                                <>
                                    <option value={language?.value} key={language?.value}>{language?.name}</option>
                                </>
                            )
                        }) }
                    </select>
                </div>

                <div className='flex items-center justify-between mt-10'>
                    {isLanguageChanged ? <>
                        <button className='pt-4 pb-4 pl-12 pr-12 border-2 border-primary-background rounded-md bg-primary-background' onClick={() => { handleUpdateButton() }}>
                        <p className='flex items-center'>
                            <span className='text-white'><Trans>UPDATE</Trans></span>
                        </p>
                    </button>
                    <button className='pt-4 pb-4 pl-12 pr-12 border-2 border-gray rounded-md bg-gray' onClick={() => setSelectedLanguage(languages[0])}>
                        <p className='flex items-center'>
                            <span className='text-deactivated'><Trans>CANCEL</Trans></span>
                        </p>
                    </button>
                    </> : ""}
                    
                </div>

            </div>
        </>
    )
}

export default LanguageSelection