import { useEffect } from "react";
import { Fragment, useState } from "react";
import MultiplePhotoUpload from "../Input/FileUpload.component";
import NoteUpload from "./NoteUpload";

export default function MyModal({
  data,
  currentIndex,
  setChecklistResponseForm,
  handleSubmitAnswer,
  uploadImage,
  deleteImage,
  deleteImageLoading,
  uploadImageLoading,
  setCurrentResponseTime,
  elapsedTime,
}) {
  const [answerInput, setAnswerInput] = useState("");
  const [currentResponse, setCurrentResponse] = useState({});

  useEffect(() => {
    setCurrentResponse(data[currentIndex]);
    setAnswerInput(data[currentIndex]?.response);
  }, [currentIndex]);

  const onClickButton = (e) => {
    data[currentIndex] = {
      ...currentResponse,
      response: e.target.value,
      answerResponseTime: elapsedTime,
    };
    //setChecklistResponseForm(data);
    // setCurrentResponse(0);
    handleSubmitAnswer();
  };

  return (
    <div className="">
      <div className="grid content-center">
        <div
          className="mt-2 flex justify-center overflow-y-scroll h-14 border-[#F2F2F2] border"
          style={{ height: "20vh" }}
        >
          <p className="w-full font-sans p-1 text-xl rounded text-black-text flex justify-left">
            {currentResponse?.question}
          </p>
        </div>
      </div>
      <div>
        <div>
          <div className="w-full flex justify-center items-baseline gap-4 mb-4">
            <div>
              <button
                type="button"
                onClick={onClickButton}
                value="OK"
                className={`${
                  answerInput !== "OK"
                    ? "button-green"
                    : "button-green-selected "
                } font-kanit text-xl`}
              >
                OK
              </button>
            </div>
            <div>
              {" "}
              <button
                type="button"
                onClick={onClickButton}
                value="NOT OK"
                className={`${
                  answerInput !== "NOT OK"
                    ? "button-red"
                    : "button-red-selected"
                } mt-4 font-kanit text-xl`}
              >
                NOT OK
              </button>
            </div>
          </div>
          <div className="flex justify-center mb-4">
            <button
              type="button"
              onClick={onClickButton}
              value="NA"
              className={`${
                answerInput !== "NA" ? "button-gray" : "button-gray-selected"
              }  mt-4 font-kanit text-xl`}
            >
              NA
            </button>
          </div>
        </div>
        <div className="flex gap-2 justify-center">
          <div>
            <MultiplePhotoUpload
              uploadImage={uploadImage}
              deleteImage={deleteImage}
              deleteImageLoading={deleteImageLoading}
              uploadImageLoading={uploadImageLoading}
              currentResponse={currentResponse}
              setCurrentResponse={setCurrentResponse}
            />
          </div>
          <div>
            {currentResponse && <NoteUpload
              currentResponse={currentResponse}
              setCurrentResponse={setCurrentResponse}
            />}
            
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-wrap pt-5">
        <div className="flex-1">
          <div className="w-full flex  justify-center"></div>
          <div className="w-full flex  justify-center"></div>
          <div className="w-full flex  justify-center"></div>
        </div>
        <div className="flex-1"></div>
      </div>
    </div>
  );
}
