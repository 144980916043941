import { authTypes } from "./types";
import { initialState } from "./initialState";

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case authTypes.LOGIN_BY_CREDENTIALS_LOADING:
            return {
                ...state,
                loginByCredentialsLoading: true,
                loginByCredentialsError: false,
                loginByCredentialsSuccess: false,
            }

        case authTypes.LOGIN_BY_CREDENTIALS_ERROR:
            return {
                ...state,
                loginByCredentialsLoading: false,
                loginByCredentialsError: true,
                loginByCredentialsSuccess: false,
            }

        case authTypes.LOGIN_BY_CREDENTIALS_SUCCESS:
            return {
                ...state,
                loginByCredentialsLoading: false,
                loginByCredentialsError: false,
                loginByCredentialsSuccess: false,
                userDetails: {
                    ...action.data
                }
            }

        case authTypes.USER_QR_CODE_SCAN_LOADING:
            return {
                ...state,
                userQRCodeScanLoading: true,
                userQRCodeScanError: false,
                userQRCodeScanSuccess: false,
            }

        case authTypes.USER_QR_CODE_SCAN_SUCCESS:
            return {
                ...state,
                userQRCodeScanLoading: false,
                userQRCodeScanError: false,
                userQRCodeScanSuccess: true,
                userDetails: {
                    ...action.data
                }
            }

        case authTypes.USER_QR_CODE_SCAN_ERROR:
            return {
                ...state,
                userQRCodeScanLoading: false,
                userQRCodeScanError: true,
                userQRCodeScanSuccess: false,
            }

        case authTypes.RESET_USER_QR_CODE_SCAN_STATE:
            return {
                ...state,
                userQRCodeScanLoading: false,
                userQRCodeScanError: false,
                userQRCodeScanSuccess: false,
            }

        case authTypes.ASSET_QR_CODE_SCAN_LOADING:
            return {
                ...state,
                assetQRCodeScanLoading: true,
                assetQRCodeScanError: false,
                assetQRCodeScanSuccess: false,
            }

        case authTypes.ASSET_QR_CODE_SCAN_SUCCESS:
            return {
                ...state,
                assetQRCodeScanLoading: false,
                assetQRCodeScanError: false,
                assetQRCodeScanSuccess: true,
            }

        case authTypes.ASSET_QR_CODE_SCAN_ERROR:
            return {
                ...state,
                assetQRCodeScanLoading: false,
                assetQRCodeScanError: true,
                assetQRCodeScanSuccess: false,
            }

        case authTypes.LOGOUT_USER:
            return {
                success: false,
            };
        // Returning default state
        default:
            return state;
    }
};