import { authTypes } from "./types";
import axios from "axios";
import { baseUrl } from "../../../src/config";
import { toast } from 'react-toastify';
import { setUserProfileWhenLogin } from "../profile/actions"

// LOGIN BY CREDENTIALS
const setLoginByCredentialsLoading = () => ({
    type: authTypes.LOGIN_BY_CREDENTIALS_LOADING,
});

const setLoginByCredentialsSuccess = (data) => ({
    type: authTypes.LOGIN_BY_CREDENTIALS_SUCCESS,
    data
});

const setLoginByCredentialsError = (err) => ({
    type: authTypes.LOGIN_BY_CREDENTIALS_ERROR,
    data: err
});

export const loginByCredentials = (data) => {
    return (dispatch, getState) => {
        let headers = {
            "Content-Type": "application/json",
        };
        dispatch(setLoginByCredentialsLoading())

        axios.post(`${baseUrl}/user/operatorSignin`, data)
            .then((res) => {
                console.log("CRED SIGNIN", res)
                if(!res.data.success){
                    toast.error("Account Number or PIN is incorrect")
                    dispatch(setLoginByCredentialsError(res.data?.message));
                }

                dispatch(setUserProfileWhenLogin(res?.data))
                dispatch(setLoginByCredentialsSuccess(res?.data))
            }).catch((err) => {
                dispatch(setLoginByCredentialsError(err))
            })

    }
}

// SCAN USER QR CODE USER_QR_CODE_SCAN_ERROR
const setUserQRCodeScanLoading = () => ({
    type: authTypes.USER_QR_CODE_SCAN_LOADING,
});

const setUserQRCodeScanSuccess = (data) => ({
    type: authTypes.USER_QR_CODE_SCAN_SUCCESS,
    data
});

const setUserQRCodeScanError = (err) => ({
    type: authTypes.USER_QR_CODE_SCAN_ERROR,
    data: err
});

export const resetUserQRCodeScanState = () => ({
    type: authTypes.RESET_USER_QR_CODE_SCAN_STATE
})

export const scanUserQRCode = (userId) => {
    return (dispatch, getState) => {
        let headers = {
            "Content-Type": "application/json",
        };

        dispatch(setUserQRCodeScanLoading())

        let data = {
            operatorId: userId,
        }

        axios.post(`${baseUrl}/user/operatorScanLogin`, data, {
            headers: headers,
        })
            .then((res) => {
                console.log(res)
                if (res?.data?.success) {
                    dispatch(setUserQRCodeScanSuccess(res?.data))
                } else {
                    dispatch(setUserQRCodeScanError(res?.message))
                }
                // dispatch(resetUserQRCodeScanState())
            }).catch((err) => {
                // dispatch(setUserQRCodeScanError())
            })

    }
}


const setAssetQRCodeScanLoading = () => ({
    type: authTypes.ASSET_QR_CODE_SCAN_LOADING,
});

const setAssetQRCodeScanSuccess = (data) => ({
    type: authTypes.ASSET_QR_CODE_SCAN_SUCCESS,
    data
});

const setAssetQRCodeScanError = (err) => ({
    type: authTypes.ASSET_QR_CODE_SCAN_ERROR,
    data: err
});

export const scanAssetQRCode = (assetId) => {
    return (dispatch, getState) => {
        let headers = {
            "Content-Type": "application/json",
        };

        dispatch(setAssetQRCodeScanLoading())

        axios.get(`${baseUrl}/asset/getassetforqr/${assetId}`, {
            headers: headers,
        })
            .then((res) => {
                console.log("ASSET DETAILS", res)
                dispatch(setAssetQRCodeScanSuccess(res?.data))
            }).catch((err) => {
                // dispatch(setAssetQRCodeScanError())
            })

    }
}

export const logoutUser = () => ({
    type: authTypes.LOGOUT_USER,
})