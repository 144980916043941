import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

function ProtectedRoute(props) {

    let navigate = useNavigate();

    let { userDetails } = props

    useEffect(() => {
        if(!userDetails?.token){
            navigate("/", { replace: true })
        }
    }, [])

    return (
        <>
            {props.children}
        </>
    )
}
const mapStateToProps = (state) => ({
    userDetails: state?.auth?.userDetails
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);