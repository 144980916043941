const Modal = ({ children, isOpen, onClose }) => {
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 text-center">
            <div
              className="fixed inset-0 bg-black opacity-30"
              onClick={onClose}
            ></div>
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl opacity-100">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
