import React, { useState, Fragment, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import Logo from "../../../assets/logo-white.png";
import { ChevronDownIcon } from '@heroicons/react/solid'
import { ReactComponent as USFlagIcon } from '../../../assets/flags/us-flag.svg';
import { ReactComponent as ESFlagIcon } from '../../../assets/flags/es-flag.svg';
import { ReactComponent as AvatarPlaceholder } from '../../../assets/avatar.svg';
import SideNav from '../../../components/SideNav';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as SampleProfileAvatar } from '../../../assets/avatar.svg';
import { s3UserImageUrl } from '../../../config';


function Header({ 
  userDetails, 
  profile 
}) {

  let navigate = useNavigate();
  const styleHeader = {
    borderTop: "20px solid #00378D",
    zIndex: "9"
  };
 
  const [language, setLanguage] = useState("EN")
  const [isSideNavVisible, setIsSideNavVisible] = useState(false)

  useEffect(() => {
    if (userDetails?.token) {
      navigate("/asset-list", { replace: true })
    } else {
      navigate("/", { replace: true })
    }
  }, [])

  return (
    <>
      <div className={`fixed w-full h-full z-20 ${isSideNavVisible ? '' : 'invisible'}`}>
        <Transition
          show={isSideNavVisible}
          enter="transition ease-in duration-100"
          enterFrom="transform opacity-50"
          enterTo="transform opacity-100"
          leave="transition ease-out duration-100"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
        >
          <SideNav isSideNavVisible={isSideNavVisible} setIsSideNavVisible={setIsSideNavVisible} />
        </Transition>
      </div>

      <div className='bg-primary-background mx-auto text-center p-4 drop-shadow-[0_3px_4px_rgba(0,0,0,0.25)] relative z-10 w-full' style={styleHeader}>
        {/* borderTop: "20px solid #004696" */}
        <div className='flex items-center'>
          <div className='flex-none w-10'>
            {userDetails?.token &&
              <span className='cursor-pointer' onClick={() => { setIsSideNavVisible(!isSideNavVisible) }}>
                {
                        profile?.updatedProfileImg ? <>
                            <img className='rounded-full w-10 h-10' src={profile?.updatedProfileImg} alt={"profile"} />
                        </> : <>
                            {userDetails?.userData?.imgData ?
                                <img className='rounded-full w-10 h-10' src={`${s3UserImageUrl}/${userDetails?.userData?.imgData}`} alt={"profile"} /> : <SampleProfileAvatar className="w-10" fill='#FFF' />}
                        </>
                    }

               </span>}

          </div>
          <div className='grow'>
            <img src={Logo} alt="SIERA.AI" className='h-5 mx-auto sm:h-10 md:h-15' />
          </div>
          <div className='flex-none w-10'>

          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  userDetails: state.auth.userDetails,
  profile: state.profile
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Header);