import { assetsTypes } from "./types";
import { initialState } from "./initialState";

export const assetsReducer = (state = initialState, action) => {
    switch (action.type) {
        case assetsTypes.ASSET_TYPE_LIST_LOADING:
            return {
                ...state,
                assetTypeListLoading: true,
                assetTypeListError: false,
                assetTypeListSuccess: false,
            }

        case assetsTypes.ASSET_TYPE_LIST_ERROR:
            return {
                ...state,
                assetTypeListLoading: false,
                assetTypeListError: true,
                assetTypeListSuccess: false,
            }

        case assetsTypes.ASSET_TYPE_LIST_SUCCESS:
            return {
                ...state,
                assetTypeListLoading: false,
                assetTypeListError: false,
                assetTypeListSuccess: true,
                assetTypeList: action.data || []
            }

        case assetsTypes.RESET_ASSET_TYPE_LIST_STATE:
            return {
                ...state,
                assetTypeListLoading: false,
                assetTypeListError: false,
                assetTypeListSuccess: false,
            }

        case assetsTypes.ASSET_LIST_LOADING:
            return {
                ...state,
                assetListLoading: true,
                assetListError: false,
                assetListSuccess: false,
            }

        case assetsTypes.ASSET_LIST_ERROR:
            return {
                ...state,
                assetListLoading: false,
                assetListError: true,
                assetListSuccess: false,
            }

        case assetsTypes.ASSET_LIST_SUCCESS:
            return {
                ...state,
                assetListLoading: false,
                assetListError: false,
                assetListSuccess: true,
                assetList: action.data || []
            }

        case assetsTypes.RESET_ASSET_LIST_STATE:
            return {
                ...state,
                assetListLoading: false,
                assetListError: false,
                assetListSuccess: false,
            }

        case assetsTypes.GET_ASSET_CHECKLIST_LOADING:
            return {
                ...state,
                assetChecklistLoading: true,
                assetChecklistSuccess: false,
                assetChecklistError: false,
            }

        case assetsTypes.GET_ASSET_CHECKLIST_ERROR:
            return {
                ...state,
                assetChecklistLoading: false,
                assetChecklistSuccess: false,
                assetChecklistError: true,
            }

        case assetsTypes.GET_ASSET_CHECKLIST_SUCCESS:
            return {
                ...state,
                assetChecklistLoading: false,
                assetChecklistSuccess: true,
                assetChecklistError: false,
                assetChecklists: action?.data?.assetChecklists,
                assetDetails: {
                    assetName: action?.data?.assetName,
                    assetImage: action?.data?.assetImage,
                    assetDescription: action?.data?.assetDescription
                }
            }


        // Returning default state
        default:
            return state;
    }
};