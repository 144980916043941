const config = {
  baseUrl: "https://dev-api.siera.ai/apiv3",
  s3UserImageUrl: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/user",
  s3AssetImageUrl: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/asset",
};

module.exports = {
  baseUrl: config.baseUrl,
  s3UserImageUrl: config.s3UserImageUrl,
  s3AssetImageUrl: config.s3AssetImageUrl,
};
