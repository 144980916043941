import React, { useEffect, useState } from 'react'
import { scanAssetQRCode, scanUserQRCode } from '../../../redux/auth/actions';
import { connect } from "react-redux";
import { ReactComponent as GrayKeyboardIcon } from '../../../assets/icons/gray-keyboard.svg';
import { ReactComponent as GrayQRCodeIcon } from '../../../assets/icons/gray-qr-code.svg';
import QrReader from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SampleProfileAvatar } from '../../../assets/avatar.svg';
import { s3UserImageUrl } from '../../../config';

function ScanningAssetQRCode({
    setLoginMethod,
    scanUserQRCode,
    userQRCodeScanSuccess,
    userDetails,
    scanAssetQRCode,
    assetQRCodeScanSuccess
}) {
    let navigate = useNavigate();


    const [assetSelectionMethod, setAssetSelectionMethod] = useState("SELECT")

    const handleBackButton = () => {
        setLoginMethod("SELECT")
        // navigate(`/asset-list`, { replace: true })
    }

    const handleAssetQRCodeSuccess = (data) => {
        console.log(data)
        let assetID = data?.split?.("/")[data?.split?.("/").length - 1];
        console.log("ASSET ID", assetID)
        if (assetID) {
            navigate(`/inspect/${assetID}`, { replace: true })
        }
    }

    const redirectToAssetList = () => {
        navigate(`/asset-list`, { replace: true })
    }

    return (

        <>
            <div className='mx-auto w-4/5 sm:w-6/12 md:w-1/2 lg:w-3/12 text-center font-sans'>

                <div class="p-6 rounded-lg shadow-lg flex items-center space-x-4 text-left border-2 border-gray mt-10 mb-10">
                    <div class="shrink-0">
                        {userDetails?.userData?.imgData ?
                            <img className='rounded-full w-14 h-14' src={`${s3UserImageUrl}/${userDetails?.userData?.imgData}`} /> :
                            <SampleProfileAvatar fill='#3c3c3c' />}
                    </div>
                    <div>
                        <div class="text-xl font-medium text-black">{userDetails?.userData?.fullName}</div>
                        <p class="text-slate-500">{userDetails?.userData?.permissions}</p>
                    </div>
                </div>



                {assetSelectionMethod === "SELECT" && <>

                    <div className='mb-10'>
                        <p className='font-semibold text-md mt-3'>Scan the Asset QR Code to<br></br> begin inspection</p>
                    </div>

                    <div className='mx-auto w-1/2 h-1/2 cursor-pointer' onClick={() => setAssetSelectionMethod("QR_CODE")}>
                        <GrayQRCodeIcon className='w-full opacity-70' fill='#323232' />
                        <p className='text-md text-black-text mt-4'>Scan Asset QR</p>
                    </div>

                    <div className='mt-6 mb-6'>
                        <p className='text-md text-deactivated'>OR</p>
                    </div>

                    <div onClick={() => redirectToAssetList()}>
                        <div className='w-16 mx-auto text-center mb-1 '>
                            <GrayKeyboardIcon className='cursor-pointer' />
                        </div>
                        <div className='w-full mx-auto text-center text-deactivated'>
                            <p className='cursor-pointer '>Select from asset list</p>
                        </div>
                    </div>
                </>}
                {assetSelectionMethod === "QR_CODE" && <>
                    <div className='mx-auto mb-4 w-full cursor-pointer'>
                        <div className='w-full'>
                            <QrReader
                                delay={2000}
                                onError={() => { }}
                                onScan={(result) => { handleAssetQRCodeSuccess(result) }}
                            />
                        </div>
                        <p className='text-md text-deactivated'>Scan Asset QR</p>
                        <div className='mt-6 mb-6'>
                        <p className='text-md text-deactivated'>OR</p>
                    </div>
                        <div onClick={() => redirectToAssetList()}>
                            <div className='w-16 mx-auto text-center mb-1 '>
                                <GrayKeyboardIcon className='cursor-pointer' />
                            </div>
                            <div className='w-full mx-auto text-center text-deactivated'>
                                <p className='cursor-pointer '>Select from asset list</p>
                            </div>
                        </div>
                    </div>
                </>}
                {/* {assetSelectionMethod === "ASSET_LIST" && <>
                    <div className='mx-auto mb-4 w-full cursor-pointer'>
                        <p className='text-md text-deactivated mt-2'>Asset List</p>
                    </div>
                </>} */}
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    userDetails: state.auth.userDetails,
    userQRCodeScanSuccess: state.auth.userQRCodeScanSuccess,
    assetQRCodeScanSuccess: state.auth.assetQRCodeScanSuccess,
    userDetails: state.auth.userDetails
});

const mapDispatchToProps = (dispatch) => ({
    scanUserQRCode: (data) => dispatch(scanUserQRCode(data)),
    scanAssetQRCode: (data) => dispatch(scanAssetQRCode(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ScanningAssetQRCode);