import React from 'react'
import LoginHeader from "../views/LandingPages/Header/Header.jsx"
import { Outlet } from "react-router-dom"

function LandingPageLayout() {
    return (
        <>
            <LoginHeader />
            <Outlet />
        </>
    );
}

export default LandingPageLayout