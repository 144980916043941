import { profileTypes } from "./types";
import { initialState } from "./initialState";
import { data } from "autoprefixer";

export const profileReducer = (state = initialState, action) => {
    switch (action.type) {

        case profileTypes.UPDATE_USER_PROFILE_LOADING: 
            return {
                ...state,
                updateProfileLoading: true,
                updateProfileError: false,
                updateProfileSuccess: false
            }

        case profileTypes.UPDATE_USER_PROFILE_ERROR:
            return {
                ...state,
                updateProfileLoading: false,
                updateProfileError: true,
                updateProfileSuccess: false
            }

        case profileTypes.UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                updateProfileLoading: false,
                updateProfileError: false,
                updateProfileSuccess: true,
                updatedProfileImg: action.data.updatedProfileImg,
                phoneNo: action.data.phoneNo,
                fullName: action.data.fullName,
            }
        
        case profileTypes.RESET_UPDATE_PROFILE_STATES:
            return {
                ...state,
                updateProfileLoading: false,
                updateProfileError: false,
                updateProfileSuccess: false
            }

        case profileTypes.SET_USER_PROFILE_DATA:
            return {
                ...state,
                profileImg: action.data.userData.imgData,
                phoneNo: action.data.userData.phone,
                fullName: action.data.userData.fullName,
                updatedProfileImg: null
            }

        default:
            return state;
    }
};