import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { HashRouter, Routes, Route, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./views/LandingPages/Login/Login";
import Header from "./views/LandingPages/Header/Header";
import LandingPageLayout from "./layouts/LandingPageLayout";
import AssetList from "./views/AssetsList/AssetList";
import UserProfile from "./views/UserProfile/UserProfile";
import LanguageSelection from "./views/LanguageSelection/LanguageSelection";
import ScanAsset from "./views/ScanAsset/ScanAsset";
import ProtectedRoute from "./components/ProtectedRoute";
import Inspection from "./views/Inspection/Inspection";
import SelectChecklist from "./views/SelectChecklist/SelectChecklist";

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route element={<LandingPageLayout />}>
            <Route path="/" element={<Login />} />
            <Route
              path="scan-asset"
              element={
                <ProtectedRoute>
                  <ScanAsset />
                </ProtectedRoute>
              }
            />
            <Route
              path="asset-list"
              element={
                <ProtectedRoute>
                  <AssetList />
                </ProtectedRoute>
              }
            />
            <Route
              path="language-selection"
              element={
                <ProtectedRoute>
                  <LanguageSelection />
                </ProtectedRoute>
              }
            />
            <Route
              path="user-profile"
              element={
                <ProtectedRoute>
                  <UserProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="inspect/:assetId"
              element={
                <ProtectedRoute>
                  <SelectChecklist />                  
                </ProtectedRoute>
              }
            />
            <Route
              path="inspect/:assetId/:checklistId"
              element={
                <ProtectedRoute>
                  <Inspection />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Login />} />
          </Route>
        </Routes>
      </HashRouter>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={"colored"}
      />
    </>
  );
}

export default App;
