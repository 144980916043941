import React, { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { ReactComponent as GrayQRCodeIcon } from "../../assets/icons/gray-qr-code.svg";
import { getAssetTypeList, getAssetList } from "../../redux/assets/actions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SelectIcon } from "../../assets/ArrowRight.svg";
import { ReactComponent as SearchIcon } from "../../assets/Gray-search.svg";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

function AssetList({
  getAssetTypeList,
  getAssetList,
  assetList,
  assetListLoading,
  assetTypeList,
  assetTypeListLoading,
}) {
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();

  const [selectedAssetType, setSelectedAssetType] = useState({
    _id: "all",
    AssetTypeName: "All",
  });
  const [filteredAssetList, setFilteredAssetList] = useState([]);

  useEffect(() => {
    getAssetTypeList();
    getAssetList();
  }, []);

  useEffect(() => {
    console.log(selectedAssetType);
    let newAssetList = assetList?.filter((asset) => {
      if (selectedAssetType?._id === "all") {
        return asset;
      } else {
        if (selectedAssetType?._id === asset?.assetTypeId?._id) {
          return asset;
        }
      }
    });
    setFilteredAssetList([...newAssetList]);
  }, [selectedAssetType]);

  useEffect(() => {
    setFilteredAssetList(assetList);
  }, [assetList]);

  const handleStartInspection = (assetId, assetName, assetImage) => {
    localStorage.setItem("assetName", assetName);
    localStorage.setItem("assetImage", assetImage);
    navigate(`/inspect/${assetId}`, { replace: true });
  };

  const handleScanButton = () => {
    navigate(`/scan-asset`, { replace: true });
  };

  return (
    <>
      <div className="w-full bg-primary-background p-3 text-white font-medium text-lg pl-10 pr-10 kanit">
        <p>
          <Trans>Asset List</Trans>
        </p>
      </div>

      <div className="w-11/12 mx-auto mt-5">
        <div className="mb-5">
          <label class="relative block">
            <input
              class="block bg-white w-full border border-slate-300 rounded-full py-3 pl-5 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-primary-background focus:ring-1 sm:text-lg"
              placeholder={t("Search an asset")}
              type="text"
              name="search"
            />
            <span class="absolute inset-y-0 right-5 flex items-center pl-2">
              <SearchIcon className="h-5 w-5" />
              {/*  <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="secondary-background"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                />
              </svg>*/}
            </span>
          </label>
        </div>

        <div className="mt-5 mb-5">
          <div className="flex items-center justify-between bg-gray p-4 rounded-lg mb-5">
            <p className="text-2xl font-kanit text-black-text">
              <Trans>Your Assets</Trans>
            </p>
            <p
              className="w-8 cursor-pointer"
              onClick={() => handleScanButton()}
            >
              <GrayQRCodeIcon />
            </p>
          </div>

          <div className="mb-2">
            <span className="pl-3 text-deactivated relative top-2">
              <Trans>Asset Type</Trans>
            </span>
            {
              <>
                <Listbox
                  value={selectedAssetType}
                  onChange={setSelectedAssetType}
                >
                  <div className="relative">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-secondary-background focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-lg rounded-none border-gray border-b-[3px]">
                      <span className="block truncate text-xl">
                        {selectedAssetType.AssetTypeName}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectIcon
                          className="w-5 h-5 text-gray-400 rotate-90"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-lg">
                        <Listbox.Option
                          key={"all"}
                          className={({ active }) =>
                            `${
                              active
                                ? "text-black bg-secondary-background/[.1]"
                                : "text-black"
                            }
                          cursor-pointer select-none relative py-2 pl-10 pr-4`
                          }
                          value={{
                            _id: "all",
                            AssetTypeName: "All",
                          }}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`${
                                  selected ? "font-medium" : "font-normal"
                                } block truncate`}
                              >
                                {"All"}
                              </span>
                              {selected ? (
                                <span
                                  className={`${
                                    active ? "text-black" : "text-black"
                                  }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                >
                                  <CheckIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        {assetTypeList.map((assetType, assetTypeIdx) => (
                          <Listbox.Option
                            key={assetType?._id}
                            className={({ active }) =>
                              `${
                                active
                                  ? "text-black bg-secondary-background/[.1]"
                                  : "text-black"
                              }
                          cursor-pointer select-none relative py-2 pl-10 pr-4`
                            }
                            value={assetType}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`${
                                    selected ? "font-medium" : "font-normal"
                                  } block truncate`}
                                >
                                  {assetType?.AssetTypeName}
                                </span>
                                {selected ? (
                                  <span
                                    className={`${
                                      active ? "text-black" : "text-black"
                                    }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                  >
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </>
            }
          </div>

          <div>
            {assetListLoading ? (
              <>
                <div className="mt-4 pt-4">
                  <div class="h-8 bg-slate-200 rounded"></div>
                  <br />
                  <div class="h-8 bg-slate-200 rounded"></div>
                  <br />
                  <div class="h-8 bg-slate-200 rounded"></div>
                  <br />
                  <div class="h-8 bg-slate-200 rounded"></div>
                  <br />
                  <div class="h-8 bg-slate-200 rounded"></div>
                </div>
              </>
            ) : (
              <>
                {filteredAssetList?.map((asset) => {
                  return (
                    <>
                      <div className="flex items-center justify-between pl-3 pb-2 mb-2 border-b-2 border-slate-100">
                        <p className="text-xl truncate text-ellipsis overflow-hidden w-8/12">
                          {asset?.assetName}
                        </p>
                        <button
                          id={asset?._id}
                          className="text-white bg-primary-background  pt-2 pb-2 rounded-md font-kanit w-1/3"
                          onClick={() => {
                            handleStartInspection(
                              asset?._id,
                              asset?.assetName,
                              asset?.assetImage
                            );
                          }}
                        >
                          <Trans>INSPECT</Trans>
                        </button>
                      </div>
                    </>
                  );
                })}
                {filteredAssetList?.length === 0 && (
                  <p className="text-center mt-5">
                    <Trans>No asset found</Trans>.
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDetails: state.auth.userDetails,
  assetList: state.assets.assetList,
  assetListLoading: state.assets.assetListLoading,
  assetTypeList: state.assets.assetTypeList,
  assetTypeListLoading: state.assets.assetTypeListLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getAssetTypeList: () => dispatch(getAssetTypeList()),
  getAssetList: () => dispatch(getAssetList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetList);
