import { baseUrl } from "../config";

import axios from "axios";
import { store } from "../redux/store"
import { createBrowserHistory } from 'history';
// import { refreshToken } from "../redux/user/user.action";
// import { setErrorCode } from "../redux/errors/action"

export const apiCall = (
  endpoint,
  customConfig
) => {

  const x = {
    method: "",
    url: "",
    data: "",
    token: "",
    type: "",
    cancelPreviousRequest: ""
  }

  const config = {
    method: "GET",
    ...customConfig
  }

  const state = store.getState()
  const authToken = state?.auth?.userDetails?.token;
  const history = createBrowserHistory();

  let cancelToken;
  if (config?.cancelPreviousRequest) {
    cancelToken = axios.CancelToken.source().token;
  }
  // if (!token) {
  //   console.log(`Calling Api ${url}`);
  // }
  var axiosOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    url: `${baseUrl}${endpoint}`,
    method: config?.method || "get",
  };
  if (config?.type === "file") {
    axiosOptions.headers["Content-Type"] = "multipart/form-data";
  }
  if (authToken) {
    axiosOptions.headers["auth-token"] = `${authToken}`;
    console.log(`Calling Secured Api ${endpoint}`);
  }
  if (config?.data) {
    axiosOptions.data = config?.data;
  }
  if (cancelToken) {
    axiosOptions.cancelToken = cancelToken;
  }

  return new Promise(function (resolve, reject) {
    axios(axiosOptions)
      .then((response) => {
        console.log(response)
        if (Number(response?.data?.status) === 500) {
          // store.dispatch(setErrorCode(500))
        }
        if (Number(response?.data?.status) === 404) {
          // store.dispatch(setErrorCode(404))
        }
        if (!response?.data?.success && response?.data?.status === 401) {
          // store.dispatch(refreshToken())
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        if (Number(error?.response?.status) === 500) {
          // store.dispatch(setErrorCode(500))
        }
        if (Number(error?.response?.status) === 404) {
          // store.dispatch(setErrorCode(404))
        }
        reject(error);
      });
  });
};
