import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { ReactComponent as SampleProfileAvatar } from '../../assets/avatar.svg'
import { s3UserImageUrl } from '../../config'
import axios from 'axios'
import { baseUrl } from '../../config';
import { updateUserProfile, resetUpdateUserStates } from "../../redux/profile/actions"
import SampleAvatarImage from '../../assets/avatar.svg';
import { Trans } from 'react-i18next';


const UserProfileEditView = ({ 
  userDetails, 
  setIsEditEnabled, 
  updateUserProfile, 
  resetUpdateUserStates, 
  updateProfileLoading, 
  updateProfileSuccess,
  profile 
}) => {

  const [isImageLoadError, setIsImageLoadError] = useState(false)
  const [isImageChanged, setIsImageChanged] = useState(false)
  const [userData, setUserData] = useState({
    fullName: profile?.fullName || "NA",
    phone: profile?.phoneNo,
  })

  const [image, setImage] = useState({
    preview: `${s3UserImageUrl}/${userDetails?.userData?.imgData}`,
    raw: ""
  })

  const handleChange = e => {
    setIsImageLoadError(false)
    setIsImageChanged(true)
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };

  const handleUpdateProfile = (value) => {

    const postData = {
      fullName: userData?.fullName,
      phoneNo: userData?.phone,
      profileImage: image?.raw,
      updatedProfileImg: image?.preview || ""
    }

    updateUserProfile(postData)

  }

  const handleImageLoadError = (currentTarget) => {
    console.log("ERROR")
    currentTarget.onerror = null; // prevents looping
    currentTarget.src=SampleAvatarImage;
  }

  useEffect(() => {
    if(updateProfileSuccess){
      setIsEditEnabled(false)
      resetUpdateUserStates()
    }
  }, [updateProfileSuccess])



  return (
    <>
      <div className='mt-20'>
        <div className='flex  justify-center '>
          {
            profile?.updatedProfileImg && !isImageChanged ? <>
              <img className='rounded-full w-32 h-32' src={profile?.updatedProfileImg} alt={"profile"} onError={({ currentTarget }) => handleImageLoadError(currentTarget)} />
            </> : <>
              {
                userDetails?.userData?.imgData ?
                  !isImageLoadError && <img className='rounded-full w-32 h-32' src={image?.preview} alt={"user profile"} onError={({ currentTarget }) => handleImageLoadError(currentTarget)} onLoad={() => setIsImageLoadError(false)} /> :
                  <SampleProfileAvatar className="w-32" fill='#3c3c3c' />
              }
              {
                isImageLoadError && <SampleProfileAvatar className="w-32" fill='#3c3c3c' />
              }
            </>
          }

          
        </div>

        <div className='mt-4'>
          <input accept="image/*" type="file" name="profilePic" id="profilePic" className="inputfile" onChange={handleChange} />
          <label for="profilePic"><Trans>Change Profile Photo</Trans></label>
        </div>

        <div className='mt-6 mb-10'>
          <p className='mb-6'>
            <label className="block text-left rounded-md">
              <span className=" block text-sm font-medium text-slate-700 bg-background-gray pl-2 pt-2 pr-2 opacity-80"><Trans>First and Last Name</Trans></span>
              <input type="text" value={userData?.fullName} className="peer bg-background-gray w-full focus:outline-none p-2 text-black font-noraml disabled:text-deactivated" onChange={(e) => setUserData({ ...userData, fullName: e.target.value })} />
            </label>
          </p>

          <p>
            <label className="block text-left rounded-md">
              <span className=" block text-sm font-medium text-black-text bg-background-gray pl-2 pt-2 pr-2 opacity-80"><Trans>Phone</Trans></span>
              <input type="tel" value={userData?.phone} placeholder={"+1 (XXX) XXXX XXXX"} className="peer bg-background-gray w-full focus:outline-none p-2 text-black font-noraml disabled:text-black-text" onChange={(e) => setUserData({ ...userData, phone: e.target.value })} />
            </label>
          </p>
        </div>
        <div className='flex items-center justify-between'>
          {
            updateProfileLoading ? <>
              <button className='py-3 px-8  border-2 border-color-green rounded-md bg-color-green text-green-800 w-full'>
                <p className=' flex items-center w-full justify-center'>
                  <svg role="status" className="inline w-5 h-5 text-gray-200 animate-spin fill-white" viewBox="0 0 100 101" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                </p>
              </button>
            </> : <>
              <button className='py-3 px-8  border-2 border-color-green rounded-md bg-color-green' onClick={() => { handleUpdateProfile() }}>
                <p className='flex items-center'>
                  <span className='mr-2'><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#FFF">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg></span>
                  <span className='text-white'><Trans>SAVE</Trans></span>
                </p>
              </button>
              <button className='py-3 px-8  border-2 border-gray rounded-md bg-gray' onClick={() => { setIsEditEnabled(false) }}>
                <p className='flex items-center'>
                  <span className='text-deactivated'><Trans>CANCEL</Trans></span>
                </p>
              </button>
            </>
          }

        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  userDetails: state?.auth?.userDetails,
  updateProfileLoading: state.profile.updateProfileLoading,
  updateProfileError: state.profile.updateProfileError,
  updateProfileSuccess: state.profile.updateProfileSuccess,
  profile: state.profile
});

const mapDispatchToProps = (dispatch) => ({
  updateUserProfile: (data) => dispatch(updateUserProfile(data)),
  resetUpdateUserStates: () => dispatch(resetUpdateUserStates()),
  resetUpdateUserStates: () =>dispatch(resetUpdateUserStates())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileEditView);