export const initialState = {
    loginByCredentialsLoading: false,
    loginByCredentialsError: false,
    loginByCredentialsSuccess: false,
    userQRCodeScanLoading: false,
    userQRCodeScanError: false,
    userQRCodeScanSuccess: false,
    assetQRCodeScanLoading: false,
    assetQRCodeScanError: false,
    assetQRCodeScanSuccess: false,
    token: null,
}