export const authTypes = {
    LOGIN_BY_CREDENTIALS_LOADING: "LOGIN_BY_CREDENTIALS_LOADING",
    LOGIN_BY_CREDENTIALS_SUCCESS: "LOGIN_BY_CREDENTIALS_SUCCESS",
    LOGIN_BY_CREDENTIALS_ERROR: "LOGIN_BY_CREDENTIALS_ERROR",
    USER_QR_CODE_SCAN_LOADING: "USER_QR_CODE_SCAN_LOADING",
    USER_QR_CODE_SCAN_SUCCESS: "USER_QR_CODE_SCAN_SUCCESS",
    USER_QR_CODE_SCAN_ERROR: "USER_QR_CODE_SCAN_ERROR",
    ASSET_QR_CODE_SCAN_LOADING: "ASSET_QR_CODE_SCAN_LOADING",
    ASSET_QR_CODE_SCAN_SUCCESS: "ASSET_QR_CODE_SCAN_SUCCESS",
    ASSET_QR_CODE_SCAN_ERROR: "ASSET_QR_CODE_SCAN_ERROR",
    LOGOUT_USER: "LOGOUT_USER",
    RESET_USER_QR_CODE_SCAN_STATE: "RESET_USER_QR_CODE_SCAN_STATE"
}