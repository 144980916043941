import React, { Fragment, useState } from "react";
import { useEffect, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import UserProfileView from "./UserProfileView";
import { ReactComponent as GrayQRCodeIcon } from "../assets/icons/gray-qr-code.svg";
import { ReactComponent as AssetListIcon } from "../assets/Asset_List.svg";
import { ReactComponent as LanguageSelectionIcon } from "../assets/Language.svg";
import { ReactComponent as UserProfileIcon } from "../assets/User_Profile.svg";
import { ReactComponent as LogoutIcon } from "../assets/Logout.svg";
import { ReactComponent as TerminateInspectionIcon } from "../assets/Terminate_Inspection.svg";

import { ChevronRightIcon } from "@heroicons/react/solid";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../redux/auth/actions";
import { connect } from "react-redux";
import { setInspectionStatus } from "../redux/inspection/actions";
import { Trans } from "react-i18next";
import Modal from "./Modal";

const styleHeader = {
  display: "none",
};
const navigationItems = [
  {
    icon: <GrayQRCodeIcon fill="#3c3c3c" />,
    to: "/scan-asset",
    linkTitle: "Scan Asset",
  },
  {
    icon: <AssetListIcon fill="#3c3c3c" />,
    to: "/asset-list",
    linkTitle: "Asset List",
  },
  /* SIER-7817 {
    icon: <LanguageSelectionIcon fill="#3c3c3c" />,
    to: "/language-selection",
    linkTitle: "Language Selection",
  }, */
  {
    icon: <UserProfileIcon fill="#3c3c3c" />,
    to: "/user-profile",
    linkTitle: "User Profile",
  },
];

const terminateInspectionNavigationItems = [
  {
    icon: <TerminateInspectionIcon fill="#3c3c3c" />,
    to: "/",
    linkTitle: "Terminate Inspection",
  },
];

function SideNav({
  isSideNavVisible,
  setIsSideNavVisible,
  logoutUser,
  inspectionStatus,
  setInspectionStatus,
}) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const hideSideNavigation = () => {
    setIsSideNavVisible(false);
  };

  const handleLogOut = () => {
    navigate("/"); //go to home before logout
    hideSideNavigation();
    logoutUser();
  };

  //Confirm logout before actual logout
  const confirmLogout = () => {
    setShow(true);
  };

  const handleTerminateNavigation = () => {
    setInspectionStatus(false);
    hideSideNavigation();
  };
  return (
    <>
      <div className="flex z-20">
        <div className="bg-white h-screen basis-full">
          <div>
            <span
              onClick={() => hideSideNavigation()}
              className="float-right mt-3 mr-4 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
            <UserProfileView />
          </div>
          <div className="w-full">
            {inspectionStatus
              ? terminateInspectionNavigationItems.map((item) => (
                  <Link
                    to={item?.to}
                    onClick={() => handleTerminateNavigation()}
                  >
                    <div class="flex items-center space-x-4 text-left border-b-2 border-slate-200 p-4 cursor-pointer">
                      <div class="shrink-0 w-8">{item?.icon}</div>
                      <div class="grow">
                        <div class="text-xl font-medium text-black-text">
                          <Trans>{item?.linkTitle}</Trans>
                        </div>
                      </div>
                      <div class="shrink-0 w-8">
                        <ChevronRightIcon
                          className="text-deactivated"
                          strokeWidth="1"
                        />
                      </div>
                    </div>
                  </Link>
                ))
              : navigationItems.map((item) => (
                  <Link to={item?.to} onClick={() => hideSideNavigation()}>
                    <div class="flex items-center space-x-4 text-left border-b-2 border-slate-200 p-4 cursor-pointer">
                      <div class="shrink-0 w-8">{item?.icon}</div>
                      <div class="grow">
                        <div class="text-xl  text-black-text font-kanit">
                          <Trans>{item?.linkTitle}</Trans>
                        </div>
                      </div>
                      <div class="shrink-0 w-8">
                        <ChevronRightIcon
                          className="text-deactivated"
                          strokeWidth="1"
                        />
                      </div>
                    </div>
                  </Link>
                ))}

            <Link to="/" onClick={confirmLogout}>
              <div class="flex items-center space-x-4 text-left border-b-2 border-slate-200 p-4 cursor-pointer">
                <div class="shrink-0 w-8">
                  <LogoutIcon fill="#3c3c3c" />
                </div>
                <div class="grow">
                  <div class="text-xl  text-black-text font-kanit">
                    <Trans>Logout</Trans>
                  </div>
                </div>
                <div class="shrink-0 w-8">
                  <ChevronRightIcon
                    className="text-deactivated"
                    strokeWidth="1"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div
          onClick={() => hideSideNavigation()}
          className="bg-slate-700 h-screen opacity-80"
        ></div>
      </div>
      <Modal isOpen={show} onClose={() => setShow(false)}>
        <div style={{ textAlign: "center", fontSize: 22, margin: "30px 0" }}>
          Are you sure you want to logout?
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <button
              onClick={handleLogOut}
              className="mt-5 font-kanit text-white bg-secondary-background pl-8 pr-8 pt-3 pb-3 w-52 rounded font-normal"
            >
              <Trans>YES</Trans>
            </button>
            <br />
            <button
              onClick={() => setShow(false)}
              className="mt-5 font-kanit  pl-8 pr-8 pt-3 pb-3 w-52 rounded font-normal"
            >
              <Trans>CANCEL</Trans>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => ({
  userDetails: state.auth.userDetails,
  inspectionStatus: state.inspect.inspectionStatus,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch({ type: "USER_LOGOUT" }),
  setInspectionStatus: (data) => dispatch(setInspectionStatus(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
