export const en = {
    translations: {
        "Welcome!": "Welcome!",
        "Let's get started!": "Let's get started!",
        "Scan the User QR Code to": "Scan the User QR Code to",
        "begin inspection":"begin inspection",
        "Scan User QR":"Scan User QR",
        "Enter credentials manually":"Enter credentials manually",
        "Language Selection": "Language Selection",
        "Scan Asset": "Scan Asset",
        "Asset List": "Asset List",
        "User Profile": "User Profile",
        "Terminate Inspection": "Terminate Inspection",
        "Logout": "Logout",
        "Search an asset": "Search an asset",
        "Your Assets": "Your Assets",
        "Asset Type": "Asset Type",
        "INSPECT": "INSPECT",
        "No asset found": "No asset found",
        "Select your preferred language": "Select your preferred language",
        "EDIT": "EDIT",
        "Phone": "Phone",
        "Change Profile Photo": "Change Profile Photo",
        "First and Last Name": "First and Last Name",
        "SAVE": "SAVE",
        "Welcome": "Welcome",
        "You are inspecting": "You are inspecting",
        "No Checklist Assigned": "No Checklist Assigned",
        "You've reached your inspection limit": "You've reached your inspection limit"
    }
}
