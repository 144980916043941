import React, { useEffect, useState } from 'react'

import { scanAssetQRCode, scanUserQRCode } from '../../../redux/auth/actions';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScanningUserQRCode from './ScanUserQRCode';
import ScanningAssetQRCode from './ScanAssetQRCode';

function LoginByQRCode({
    setLoginMethod,
    scanUserQRCode,
    userQRCodeScanSuccess,
    userDetails,
    scanAssetQRCode,
    assetQRCodeScanSuccess,
    userQRCodeScanError
}) {

    let navigate = useNavigate();

    const [loginByQRStatus, setLoginByQRStatus] = useState("SCAN_USER")
    
    const [scannedAssetId, setScannedAssetId] = useState("")

    const handleBackButton = () => {
        setLoginMethod("SELECT")
    }

    const handleAssetQRCodeSuccess = (data) => {
        // console.log(data)
        let assetID = data?.split?.("/")[data?.split?.("/").length - 1];
        // console.log("ASSET ID", assetID)
        if (assetID) {
            scanAssetQRCode(assetID)
            setScannedAssetId(assetID)
            // scanUserQRCode(userID)
            // scanAssetQRCode
        }
    }

    useEffect(() => {
        if (userQRCodeScanSuccess) {
            setLoginByQRStatus("SCAN_ASSET")
        }
    }, [userQRCodeScanSuccess])

    useEffect(() => {
        if (assetQRCodeScanSuccess) {
            navigate(`/inspect/${scannedAssetId}`, { replace: true })
        }
    }, [assetQRCodeScanSuccess])

    

    return (
        <>
            {
                loginByQRStatus === "SCAN_USER" &&
                <ScanningUserQRCode 
                    handleBackButton={handleBackButton}
                    setLoginMethod={setLoginMethod} />
            }

            {
                loginByQRStatus === "SCAN_ASSET" &&
                <ScanningAssetQRCode 
                handleAssetQRCodeSuccess={handleAssetQRCodeSuccess}
                setLoginMethod={setLoginMethod}/>
            }

        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.auth.userDetails,
    userQRCodeScanSuccess: state.auth.userQRCodeScanSuccess,
    userQRCodeScanError: state.auth.userQRCodeScanError,
    assetQRCodeScanSuccess: state.auth.assetQRCodeScanSuccess,
    userDetails: state.auth.userDetails
});

const mapDispatchToProps = (dispatch) => ({
    scanUserQRCode: (data) => dispatch(scanUserQRCode(data)),
    scanAssetQRCode: (data) => dispatch(scanAssetQRCode(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginByQRCode);