import React, { Fragment, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react';
import tipMessage from '../../constants/messageTips';
import { ReactComponent as HrLine } from '../../assets/hr-line.svg';
import { ReactComponent as InformationTipIcon } from '../../assets/Information_Tooltip.svg';
import { Trans } from 'react-i18next';

function InspectionLimitError({ uploadImage }) {

    let [isOpen, setIsOpen] = useState(true)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    let navigate = useNavigate();
    const assetName = 'Test Asset';

    return (
        <>
            <Transition appear show={true} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    <div className="min-h-screen px-4 text-center">

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-2xl opacity-100">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    {/* <Trans>Payment successful</Trans> */}
                                </Dialog.Title>
                                <div className='mt-4'>
                                    <p className='text-blue-600 font-bold text-2xl'><Trans>You've reached your inspection limit</Trans>!</p>
                                    <br />
                                    <p className='text-gray-600 text-xl'><Trans>Please upgrade your plan in order to perform unlimited insepctions</Trans>.</p>
                                    <br />
                                    <p className='text-gray-600 text-xl'><Trans>Contact</Trans> <a className='text-blue-600 text-xl underline' href="support@siera.ai">support@siera.ai</a> <Trans>to upgrade your inspection plan</Trans>.</p>
                                    <br />
                                    <button
                                        onClick={() => { navigate('/asset-list'); }}
                                        className='mt-5 font-kanit text-white bg-secondary-background pl-8 pr-8 pt-3 pb-3 w-52 rounded font-normal'
                                    >
                                        <Trans>CONTINUE</Trans>
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default InspectionLimitError;
