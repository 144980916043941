import { useEffect } from 'react';
import OTPInput from "otp-input-react";
import { Fragment, useState } from 'react'
import MultiplePhotoUpload from '../Input/FileUpload.component';

export default function MeterReading({
    data,
    currentIndex,
    setChecklistResponseForm,
    handleSubmitAnswer,
    showSelection,
    uploadImage,
    deleteImage,
    deleteImageLoading,
    uploadImageLoading,
    elapsedTime
}) {
    const [answerText, setAnswerText] = useState("");
    const [currentResponse, setCurrentResponse] = useState({});
    const [numericValidation, setNumericValidation] = useState('');
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setCurrentResponse(data[currentIndex]);
        setAnswerText(data[currentIndex]?.response);
        setShowError(false);
    }, [currentIndex]);

    const onClickSubmit = () => {
        setShowError(true);
        if (!answerText) {
            setNumericValidation('Numeric response is required');
            return;
        }
        if (!(/^\d+$/.test(answerText))) {
            setNumericValidation('Please enter valid number');
            return;
        }
        data[currentIndex] = {
            ...currentResponse,
            response: answerText,
            answerResponseTime: elapsedTime
        }
        //setChecklistResponseForm(data);
        handleSubmitAnswer();
    };

    useEffect(() => {
        if (!answerText) {
            setNumericValidation('Numeric response is required');
            return;
        }
        if (!(/^\d+$/.test(answerText))) {
            setNumericValidation('Please enter valid number');
            return;
        }
        setNumericValidation('');
    }, [answerText]);

    const onClickNA = () => {
        data[currentIndex] = {
            ...currentResponse,
            response: 'NA',
            answerResponseTime: elapsedTime
        }
        //setChecklistResponseForm(data);
        handleSubmitAnswer();
    };

    return (
        <>
            <div className="">
                <div className='grid content-center'>
                    <div className="mt-2 flex justify-center overflow-y-scroll h-14 border-[#F2F2F2] border" style={{ height: '20vh' }}>
                        <p className="w-full font-sans p-1  text-xl rounded text-black-text flex justify-left">
                            {currentResponse?.question}
                        </p>
                    </div>
                    <br />
                    <div>
                        <label className='text-sm font-sans text-color-blackDark'>Numeric Response</label>
                        <textarea
                            id="about"
                            name="about"
                            type="number"
                            rows={4}
                            className="focus:border-blue-500 p-2 font-sans block w-full  text-xl border-b text-black-text border-gray-300"
                            placeholder={currentResponse?.answers}
                            value={answerText ? answerText : ''}
                            onChange={(e) => setAnswerText(e.target.value)}
                        />
                    </div>
                    {showError && numericValidation && <div className="text-color-red flex justify-left font-sans text-md">{numericValidation}</div>}
                </div>

                <div className='flex justify-center flex-wrap pt-5'>
                    <div className='flex-1'>
                        <MultiplePhotoUpload
                            deleteImageLoading={deleteImageLoading}
                            uploadImageLoading={uploadImageLoading}
                            uploadImage={uploadImage}
                            deleteImage={deleteImage}
                            currentResponse={currentResponse}
                            setCurrentResponse={setCurrentResponse}
                        />
                    </div>

                    <div className='flex-1'>
                        <div className='w-full flex  justify-center'>
                            <button
                                type="button"
                                onClick={onClickSubmit}
                                className={`${(showSelection && answerText !== 'NA') ? 'button-green-selected' : 'button-green'} font-kanit text-xl`}
                            >
                                SUBMIT
                            </button>
                        </div>
                        <div className='w-full flex  justify-center'>
                            <button
                                type="button"
                                onClick={onClickNA}
                                className={`${(showSelection && answerText === 'NA') ? 'button-gray-selected' : 'button-gray'} mt-4 font-kanit text-xl`}
                            >
                                NA
                            </button>
                        </div>
                    </div>



                </div>


            </div>
        </>
    )
}
