import React, { useState, useEffect } from "react";
import QrReader from "react-qr-reader";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Trans } from 'react-i18next';

import { scanUserQRCode } from "../../../redux/auth/actions";
import { ReactComponent as GrayKeyboardIcon } from '../../../assets/icons/gray-keyboard.svg';

function ScanningUserQRCode({
  handleBackButton,
  scanUserQRCode,
  userQRCodeScanLoading,
  userQRCodeScanError,
  setLoginMethod
}) {
  const [errorMessage, setErrorMessage] = useState("");

  const handleUserQRCodeSuccess = (data) => {
    setErrorMessage("");

    console.log("DATA", data);
    let tData = data?.split("/");
    console.log("TDATA", tData);
    // IF QRCODE SCANS SOME DATA
    if (data) {
      // IF THE DATA IS OF A USER BADGE THEN
      // PROCEED WITH LOGIN
      // ELSE SHOW ERROR

      if (tData?.includes("redirect-scan-badge-user")) {
        let userID = tData[tData?.length - 1];
        userID && scanUserQRCode(userID);
      } else {
        // setErrorMessage("WRONG QR CODE")
        toast.error("WRONG QR CODE");
      }
    }

    // console.log("TDATA", tData)

    // let userID = data?.split?.("/")[data?.split?.("/").length - 1];
    // console.log("USERID", userID)

    // if (userID) {
    //     scanUserQRCode(userID)
    // }
  };

  useEffect(() => {
    if (userQRCodeScanError) {
      setErrorMessage("User not found please scan a valid QR Code");
    }
  }, [userQRCodeScanError]);

  useEffect(() => {}, [userQRCodeScanLoading]);

  return (
    <>
      {userQRCodeScanLoading ? (
        <>
          <div className="flex flex-col items-center justify-center h-[80vh]">
            <p className="mb-4">
              <svg
                className="animate-spin  h-5 w-5 text-primary-background"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </p>

            <p>Fetching user details</p>
          </div>
        </>
      ) : (
        <>
          {/* <div className='w-full bg-secondary-background p-3 text-white font-base text-lg flex items-center'>
                    <span className='mr-1 cursor-pointer' onClick={() => { handleBackButton() }}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
                        </svg>
                    </span>
                    <p>Scan User QR</p>
                </div> */}
          <div className="mx-auto w-full sm:w-6/12 md:w-1/2 lg:w-3/12 text-center font-sans">
            <div className="mb-3">
              <p className="text-primary-background font-normal text-3xl mt-5 font-kanit">
                Welcome!
              </p>
              <p className="text-lg mt-1">Let's get started!</p>
            </div>
            <div className="mb-6">
              <p className="font-semibold text-md mt-3">
                Please scan the USER QR badge
              </p>
            </div>

            <QrReader
              delay={2000}
              onError={() => {}}
              onScan={(result) => {
                handleUserQRCodeSuccess(result);
              }}
            />
          </div>
        </>
      )}

      {errorMessage && (
        <div className="w-full text-center">
          <p className="p-6 bg-red-500 text-white">{errorMessage}</p>
        </div>
      )}

      <div
        className="cursor-pointer"
        onClick={() => {
          setLoginMethod("CREDENTIALS");
        }}
      >
        <div className="w-16 mx-auto text-center mb-1">
          <GrayKeyboardIcon />
        </div>
        <div className="w-full mx-auto text-center text-black-text">
          <p>
            <Trans>Enter credentials manually</Trans>
          </p>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userQRCodeScanError: state.auth.userQRCodeScanError,
  userQRCodeScanLoading: state.auth.userQRCodeScanLoading,
});

const mapDispatchToProps = (dispatch) => ({
  scanUserQRCode: (data) => dispatch(scanUserQRCode(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScanningUserQRCode);
