import React from "react";
import { useNavigate } from "react-router-dom";
import tipMessage from "../../constants/messageTips";
import { ReactComponent as HrLine } from "../../assets/hr-line.svg";
import { ReactComponent as InformationTipIcon } from "../../assets/Information_Tooltip.svg";
import { s3AssetImageUrl } from "../../config";

function InspectionResult({
  uploadImage,
  deleteImage,
  postInspectionResponse,
  inspectionPass,
  userFullName,
  setChecklistResponseForm,
  logoutUser,
}) {
  let navigate = useNavigate();
  const assetName = "Test Asset";

  return (
    <div className="">
      <div className="flex items-center justify-center">
        {inspectionPass ? (
          <span className="text-4xl font-normal font-kanit text-[#11A70B]">
            Inspection Pass!
          </span>
        ) : (
          <span className="text-4xl font-normal font-kanit text-[#DD0000]">
            Inspection Fail!
          </span>
        )}
      </div>
      <br />
      <div className="flex items-center justify-center text-center">
        {inspectionPass ? (
          <span className="text-md font-sans font-normal text-[#323232]">
            Have a good day <b>{userFullName}</b>!
          </span>
        ) : (
          <span className="text-md font-sans font-normal text-[#323232]">
            Please don't use this asset. Report to your worksite manager for
            further instructions
          </span>
        )}
      </div>

      <br />
      {inspectionPass && (
        <div className="flex shadow-[2px_2px_10px_rgba(0,0,0,0.5)] m-auto border-red-400 w-11/12 rounded-lg">
          <div className="bg-[#F2F2F2] w-10">
            <InformationTipIcon className="p-2 w-full" />
          </div>
          <div className="p-2">
            <div className="text-[#0D5FBE] text-md font-normal font-kanit">
              Tip
            </div>
            <div className="text-[#323232] text-sm  font-normal font-sans">
              {tipMessage[Math.floor(Math.random() * 8) + 0]}
            </div>
          </div>
        </div>
      )}
      <HrLine className="flex w-full mt-3 mb-4" />
      <br />
      {localStorage.getItem("assetImage") && (
        <div
          className="w-24 h-24 rounded-full mx-auto"
          style={{
            border: "1px solid rgb(13 95 190 / var(--tw-bg-opacity))",
            overflow: "hidden",
          }}
        >
          <img
            alt=""
            src={`${s3AssetImageUrl}/${localStorage.getItem("assetImage")}`}
            className="w-24 h-24 rounded-full mx-auto"
          />
        </div>
      )}
      <div className="flex items-center justify-center">
        <div className="text-[#323232] font-kanit text-2xl font-normal">
          {localStorage.getItem("assetName")}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <button
          onClick={() => {
            navigate("/asset-list");
            setChecklistResponseForm({});
          }}
          className="mt-5 font-kanit text-white bg-secondary-background pl-8 pr-8 pt-3 pb-3 bg-[#0D5FBE] w-52 rounded font-normal"
        >
          NEW INSPECTION
        </button>
      </div>
      <br />
      <div className="flex items-center justify-center">
        <button
          onClick={() => {
            logoutUser();
            navigate("/");
          }}
          className="font-kanit text-[#0D5FBE] pl-8 pr-8 pt-3 pb-3 border border-[#0D5FBE] w-52 rounded font-normal"
        >
          LOGOUT
        </button>
      </div>
    </div>
  );
}

export default InspectionResult;
