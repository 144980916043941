export const initialState = {
    assetTypeListLoading: false,
    assetTypeListError: false,
    assetTypeListSuccess: false,
    assetTypeList: [],
    assetListLoading: false,
    assetListError: false,
    assetListSuccess: false,
    assetList: [],
    assetChecklistLoading: false,
    assetChecklistSuccess: false,
    assetChecklistError: false,
    assetChecklists: [],
    assetDetails: {},
}