import React, { useState } from 'react'
import { ArrowCircleLeftIcon } from '@heroicons/react/solid'
import ProfileSample from "../../assets/profile-sample.png"
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { baseUrl } from '../../config';
import { connect } from "react-redux";
import UserProfileCardView from "./UserProfileView"
import UserProfileEditView from "./UserProfileEditView"
import { Trans } from 'react-i18next';

function UserProfile({token}) {
    let navigate = useNavigate();

    const [isEditEnabled, setIsEditEnabled] = useState(false)
    const [useDataLocal, setUseDataLocal] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const handleBackButton = () => {
        navigate("/asset-list", { replace: true })
    }

    const updateProfile = (value) => {

        let headers = {
            "Content-Type": "multipart/form-data",
            "auth-token": token,
        };

        let userData = {
            fullName: value.FullName,
            phone: value.Phone,
        };

        let formData = new FormData();
        value.SelectedFile && formData.append("profileImg", value.SelectedFile);
        formData.append("userData", JSON.stringify(userData));

        axios.put(`${baseUrl}/user/update/profile`, formData, {
            headers: headers,
        })
            .then((resp) => {
                console.log(resp);
                // dispatch(updateProfileSuccess({ value }));
            })
            .catch((err) => {
                // dispatch(updateProfileError(err));
                console.log(err);
            });
    }

    return (
        <> 
            <div className='w-full bg-primary-background p-3 text-white font-light text-lg flex items-center font-kanit drop-shadow-[0_2px_4px_rgba(0,0,0,0.65)]'>
                <span className='mr-1' onClick={() => handleBackButton()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
                    </svg>
                </span>
                <p><Trans>User Profile</Trans></p>
            </div>
            <div className='mx-auto w-4/5 sm:w-6/12 md:w-1/2 lg:w-3/12 text-center font-sans'>

                { isEditEnabled ? <UserProfileEditView setIsEditEnabled={setIsEditEnabled} />  : <UserProfileCardView  />}

                <div>
                    {isEditEnabled ? <>
                        
                    </> :
                        <button className='py-3 px-8 border-2 border-primary-background rounded-md ' onClick={() => setIsEditEnabled(true)}>
                            <p className='flex items-center'>
                                <span className='mr-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="#0D5FBE">
                                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                    </svg>
                                </span>
                                <span className='text-primary-background font-bold'><Trans>EDIT</Trans></span>
                            </p>
                        </button>
                    }
                </div>

            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state?.auth?.userDetails
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);