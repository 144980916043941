// Library Imports
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Custom Components
import LoginByCredentials from './LoginByCredentials';
import LoginByQRCode from './LoginByQRCode';
import SelectLoginMethod from './SelectLoginMethod';

function Login({ userDetails }) {
  
  let navigate = useNavigate();

  // State for holding the login method 
  // CREDENTIALS OR QRCODE
  const [loginMethod, setLoginMethod] = useState("CREDENTIALS")

  useEffect(() => {
    if (userDetails?.token && (loginMethod === "SELECT" || loginMethod==="CREDENTIALS")) {
      navigate("/asset-list", { replace: true })
    }
  }, [userDetails, loginMethod])

  return (<>
    {/* <AssetList /> */}
    <div className='mx-auto w-4/5 sm:w-6/12 md:w-1/2 lg:w-3/12 text-center font-sans'>
      {/* Selecting Login Method */}
      {loginMethod === "SELECT" && <SelectLoginMethod setLoginMethod={setLoginMethod}/> }
    </div>
    <div>
      {loginMethod === "QRCODE" && <LoginByQRCode setLoginMethod={setLoginMethod} />}
      {loginMethod === "CREDENTIALS" && <LoginByCredentials setLoginMethod={setLoginMethod} />}
    </div>
  </>
  )
}
const mapStateToProps = (state) => ({
  userDetails: state.auth.userDetails
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Login);