import React, { useEffect } from 'react'
import { connect } from "react-redux";

// Assets 
import { ReactComponent as GrayKeyboardIcon } from '../../../assets/icons/gray-keyboard.svg';
import { ReactComponent as GrayQRCodeIcon } from '../../../assets/icons/gray-qr-code.svg';
import { Trans } from 'react-i18next';
import { resetUserQRCodeScanState } from '../../../redux/auth/actions';
import { ReactComponent as ScanUserQRIcon } from '../../../assets/Scan_User.svg';

function SelectLoginMethod({ setLoginMethod, resetUserQRCodeScanState }) {

    useEffect(() => {
        if (!setLoginMethod) {

        }
        resetUserQRCodeScanState()
    }, [])

    return (
        <>
            <div>

                <div className='mb-3'>
                    <p className='text-primary-background font-semibold text-3xl mt-5'>
                        <Trans>Welcome!</Trans>
                    </p>
                    <p className='text-lg mt-1'>
                        <Trans>Let's get started!</Trans>
                    </p>
                </div>

                <div className='mb-12'>
                    <p className='font-semibold text-md mt-3'>
                        <Trans>Scan the User QR Code to</Trans>
                        <br></br>
                        <Trans>begin inspection</Trans>
                    </p>
                </div>
                
                <div className='mx-auto w-1/2 h-1/2 cursor-pointer' onClick={() => { setLoginMethod("QRCODE") }}>
                    <ScanUserQRIcon className='w-full opacity-70' fill='#323232' />


                   {/*  <p className='text-md text-black-text mt-4'>
                        <Trans>Scan User QR</Trans>
                    </p>*/}
                </div>

                <div className='mt-6 mb-4'>
                    <p className='text-md text-deactivated'>OR</p>
                </div>

                <div className='cursor-pointer' onClick={() => { setLoginMethod("CREDENTIALS") }}>
                    <div className='w-16 mx-auto text-center mb-1'>
                        <GrayKeyboardIcon />
                    </div>
                    <div className='w-full mx-auto text-center text-black-text'>
                        <p><Trans>Enter credentials manually</Trans></p>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    resetUserQRCodeScanState: () => dispatch(resetUserQRCodeScanState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectLoginMethod);