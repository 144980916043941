import { profileTypes } from "./types"
import axios from "axios";
import { baseUrl } from "../../../src/config";
import { apiCall } from "../../utils/apiCall";
import { toast } from "react-toastify";

const updateProfileLoading = () => ({
    type: profileTypes.UPDATE_USER_PROFILE_LOADING
})

const updateProfileError = (err) => ({
    type: profileTypes.UPDATE_USER_PROFILE_ERROR,
    err
})

const updateProfileSuccess = (data) => ({
    type: profileTypes.UPDATE_USER_PROFILE_SUCCESS,
    data
})

export const updateUserProfile = (data) => {
    return (dispatch, getState) => {

        let state = getState()
        let token = state?.auth?.userDetails?.token

        let headers = {
            "Content-Type": "multipart/form-data",
            "auth-token": token,
        };

        let postData = {
            fullName: data?.fullName,
            phone: data?.phoneNo,
        };

        let formData = new FormData();
        data.profileImage && formData.append("profileImg", data.profileImage);
        formData.append("userData", JSON.stringify(postData));

        dispatch(updateProfileLoading())
        axios.put(`${baseUrl}/user/update/profile`, formData, {
            headers: headers,
        })
            .then((resp) => {
                console.log(resp);
                toast.success("Profile updated successfully")
                dispatch(updateProfileSuccess(data));
            })
            .catch((err) => {
                console.log(err);
                toast.error("Something went wrong. Please try again later.")
                dispatch(updateProfileError(err));
            });
    }
}

export const resetUpdateUserStates = () => ({
    type: profileTypes.RESET_UPDATE_PROFILE_STATES
})

export const setUserProfileWhenLogin = (data) => ({
    type: profileTypes.SET_USER_PROFILE_DATA,
    data
})