import { inspectionTypes } from "./types";
import axios from "axios";
import { baseUrl } from "../../../src/config";
import { apiCall } from "../../utils/apiCall";
import moment from "moment-timezone";

export const setInspectionStatus = (data) => ({
    type: inspectionTypes.SET_INSPECTION_STATUS,
    data
})

const uploadImageLoading = (data) => ({
    type: inspectionTypes.UPLOAD_IMAGE_LOADING,
    data
});

const uploadImageSuccess = (data) => ({
    type: inspectionTypes.UPLOAD_IMAGE_SUCCESS,
    data
});

const uploadImageError = (data) => ({
    type: inspectionTypes.UPLOAD_IMAGE_ERROR,
    data
});

const deleteImageLoading = (data) => ({
    type: inspectionTypes.DELETE_IMAGE_LOADING,
    data
});

const deleteImageSuccess = (data) => ({
    type: inspectionTypes.DELETE_IMAGE_SUCCESS,
    data
});

const postInspectionResponseLoading = (data) => ({
    type: inspectionTypes.POST_INSPECTION_RESPONSE_LOADING,
    data
});

const postInspectionResponseSuccess = (data) => ({
    type: inspectionTypes.POST_INSPECTION_RESPONSE_SUCCESS,
    data
});

const postInspectionResponseError = (data) => ({
    type: inspectionTypes.POST_INSPECTION_RESPONSE_ERROR,
    data
});

const getChecklistLoading = (data) => ({
    type: inspectionTypes.GET_CHECKLIST_LOADING,
    data
});

const getChecklistSuccess = (data) => ({
    type: inspectionTypes.GET_CHECKLIST_SUCCESS,
    data
});

const getChecklistError = (data) => ({
    type: inspectionTypes.GET_CHECKLIST_ERROR,
    data
});

export const uploadImage = (data, callbackAfterUpload) => {
    return (dispatch, getState) => {
        dispatch(uploadImageLoading());
        const state = getState();

        let formData = new FormData();
        data.map((file) => formData.append("media", file));
        console.log(formData);
        apiCall(`/checklist/uploadMedia`, { method: "POST", data: formData })
            .then((resp) => {
                let media = [];
                resp?.data?.data?.map(data => {
                    media.push(data.filename);
                });
                callbackAfterUpload(media);
                dispatch(uploadImageSuccess(resp.data));
            })
            .catch((err) => {
                dispatch(uploadImageError(err));
                console.log(err);
            });
    };
};

export const deleteImage = (fileName, callbackAfterDelete) => {
    return (dispatch, getState) => {
        let headers = {
            "Content-Type": "application/json",
        };

        dispatch(deleteImageLoading())
        apiCall(`/checklist/deleteMedia/${fileName}`, { method: "GET", data: {} })
            .then((res) => {
                console.log(res)
                callbackAfterDelete(fileName);
                dispatch(deleteImageSuccess(res?.data))
            }).catch((err) => {
                // dispatch(setLoginByCredentialsError())
            })
    }
};

export const getChecklistById = (checklistId) => {
    return (dispatch, getState) => {

        let state = getState();
        dispatch(getChecklistLoading())
        apiCall(`/checklist/${checklistId}`, { method: "GET" })
            .then((res) => {
                console.log(res)
                dispatch(getChecklistSuccess(res?.data?.data))
            }).catch((err) => {
                dispatch(getChecklistError())
            })

    }
}

export const postInspectionResponse = (data, callbackAfterInspect) => {
    return (dispatch, getState) => {
        dispatch(postInspectionResponseLoading());
        const state = getState();
        let token = state?.user?.loginDetails?.token;
        let headers = {
            "Content-Type": "application/json",
            "auth-token": token,
        };
        console.log(data, '---------- form data');
        data.inspectionAt = moment().utc().toISOString()
        apiCall(`/checklist/createChecklistResponse`, { method: "POST", data: data })
            .then((resp) => {
                console.log(resp);
                callbackAfterInspect(resp?.data?.message.toLowerCase() === "pass")
                dispatch(postInspectionResponseSuccess(resp.data));
            })
            .catch((err) => {
                dispatch(postInspectionResponseError(err));
                console.log(err);
            });
    };
};
