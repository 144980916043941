import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { ReactComponent as AddUploadIcon } from "../../assets/Add.svg";
import { ReactComponent as CloseIcon } from "../../assets/cancel.svg";

export default function NoteUploadModal({
  isOpen,
  setIsOpen,
  currentResponse,
  setCurrentResponse,
}) {
  const [currentNote, setCurrentNote] = useState(currentResponse?.note || "");

  function closeModal() {
    setIsOpen(false);
  }

  const handleSave = () => {
    setCurrentResponse({
      ...currentResponse,
      note: currentNote,
    });
    setIsOpen(false);
  };

  useEffect(() => {
    console.log("Loaded : ", currentResponse)
  }, [])

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block border border-gray-500 w-full overflow-hidden text-left align-middle transition-all transform bg-[#3c3c3c] shadow-xl rounded-md text-white font-bold">
                <div className="flex justify-between bg-[#00378d] p-4 mb-2">
                  <div className=" ">
                    <h1>Add Note</h1>
                  </div>
                </div>
                <div className="p-4">
                  <textarea
                    className="border border-gray-300 bg-transparent focus:ring-0 focus:border-gray-700 p-2 rounded-md w-full"
                    minLength={4}
                    placeholder="Add your note here"
                    value={currentNote}
                    onChange={(e) => setCurrentNote(e.target.value)}
                  ></textarea>
                </div>
                <div className="flex justify-between p-4 items-baseline">
                  <div className="cursor-pointer">
                    <button
                      className="bg-gray-500 text-white font-bold py-2 px-4 rounded opacity-50 "
                      onClick={() => setIsOpen(false)}
                    >
                      CANCEL
                    </button>
                    <span className="text-gray-500"></span>
                  </div>
                  <div>
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
