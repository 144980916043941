import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import ImageUploadModal from "../ImageUploadModal.component";
import { ReactComponent as UploadIcon } from "../../../assets/Attachment.svg";
import NoteUploadModal from "../NoteUploadModal";

function NoteUpload({
  currentResponse,
  setCurrentResponse
}) {
  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    console.log(currentResponse);
  }, [])

  return (
    <div className="flex items-center justify-center p-2 rounded-md">
      {currentResponse && 
      <NoteUploadModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentResponse={currentResponse}
        setCurrentResponse={setCurrentResponse}
      />}
      <div lg={5} className="">
        <div border="primary" className="border-gray-300 text-center" onClick={() => {
            setIsOpen(true)
        }}>
          <span className="custom-file mt-4 mb-4">
            <label
              className="flex items-center justify-center border-[#919191] border p-2 rounded-sm"
              style={{ cursor: "pointer" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="upload-icon-color h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </label>
            <span
              className="font-kanit"
              style={{ fontSize: "12px", color: "#919191" }}
            >
              <Trans>NOTE</Trans>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default NoteUpload;
