import { Dialog, Transition } from "@headlessui/react";
import { BeakerIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState, useRef } from "react";
import MeterReadingInput from "./Input/MeterReading.component";
import NumericInput from "./Input/NumericInput.component";
import TextInput from "./Input/TextInput.component";
import ButtonInput from "./Input/ButtonInput.component";
import MessagePopup from "./MessagePopup.component";
import InspectionResult from "./Result.component";
import ReviewInspection from "./ReviewInspection.component";
import { ReactComponent as ArrowIcon } from "../../assets/ArrowRight.svg";
import { apiCall } from "../../utils/apiCall";
import InspectionLimitError from "./InspectionLimitError.component";
import planConstant from "../../constants/planConstant.json";
import moment from "moment-timezone";
import { Timer } from "timer-node";
import { useTimer } from "react-timer-hook";

const { inspectionPlan } = planConstant;

const formatTime = (seconds) => {
  if (seconds < 60) {
    return `${seconds} s`;
  } else {
    const duration = moment.duration(seconds, "seconds");
    const minutes = duration.minutes();
    const hours = duration.hours();
    if (hours > 0) {
      return `${hours}h ${minutes}m ${duration.seconds()}s`;
    } else {
      return `${minutes} m ${duration.seconds()} s`;
    }
  }
};

export default function InspectionModal({
  uploadImage,
  deleteImage,
  postInspectionResponse,
  postInspectionRepsonseLoading,
  deleteImageLoading,
  uploadImageLoading,
  inspectData,
  getChecklistLoading,
  handleStartInspectionButtonClick,
  handleSubmitButtonClick,
  logoutUser,
}) {
  const {
    accountId,
    inspectorId,
    assetId,
    userFullName,
    currentChecklist,
    checklistId,
    inspectionPlanDetail,
  } = inspectData;

  const [isOpen, setIsOpen] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openMessagePopup, setOpenMessagePopup] = useState(false);
  const [lastAnsweredQuestion, setLastAnsweredQuestion] = useState(-1);
  // const [sectionId, setSectionId] = useState(checklistJson[0]?.sectionId);
  // const [currentQuestion, setCurrentQuestion] = useState(checklistJson[0]);
  const [sectionId, setSectionId] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [checklistResponseForm, setChecklistResponseForm] = useState([
    ...currentChecklist,
  ]);
  const [finalReview, setFinalReview] = useState(false);
  const [resultPage, setResultPage] = useState(false);
  const [inspectionPass, setInspectionPass] = useState(false);
  const [isInspectionValid, setIsInspectionValid] = useState(false);
  const [inspectionCountLoading, setInspectionCountLoading] = useState(false);
  const [inspectionStatus, setInspectionStatus] = useState(true);

  const [timeLeft, setTimeLeft] = useState(10);
  const [isRunning, setIsRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const intervalRef = useRef(null);
  const startTimeRef = useRef(null);

  useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        setTimeLeft((prev) => Math.max(prev - 1, 0));
        setElapsedTime(Math.floor((Date.now() - startTimeRef.current) / 1000));
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [isRunning]);

  useEffect(() => {
    if(timeLeft <=0 && currentQuestion?.sectionQuestionResponseTimeStatus){
      alert("TIMEDOUT")
    }
  }, [timeLeft])

  const startTimer = (seconds) => {
    setTimeLeft(seconds);
    setElapsedTime(0);
    setIsRunning(true);
    startTimeRef.current = Date.now();
  };

  useEffect(() => {
    setChecklistResponseForm([...currentChecklist]);
  }, [currentChecklist]);

  useEffect(() => {
    setCurrentQuestion(checklistResponseForm[0]);
    setSectionId(checklistResponseForm[0]?.sectionId);
  }, [checklistResponseForm]);

  const handleSubmitAnswer = () => {
    console.log("--------------", checklistResponseForm);
    if (currentIndex + 1 == checklistResponseForm.length) {
      setFinalReview(true);
      return;
    }
    if (sectionId != checklistResponseForm[currentIndex + 1]?.sectionId) {
      setSectionId(checklistResponseForm[currentIndex + 1]?.sectionId);
    }
    if (currentIndex >= lastAnsweredQuestion) {
      setLastAnsweredQuestion(currentIndex);
    }
    setCurrentIndex(currentIndex + 1);
    setCurrentQuestion(checklistResponseForm[currentIndex + 1]);
  };

  const setNext = () => {
    if (currentIndex >= lastAnsweredQuestion) {
      return;
    }
    setCurrentIndex(currentIndex + 1);
    setCurrentQuestion(checklistResponseForm[currentIndex + 1]);
    setChecklistResponseForm(0);
    console.log(currentIndex + 1, "--------------", checklistResponseForm);
  };

  const setPrevious = () => {
    if (currentIndex == 0) {
      return;
    }
    setCurrentIndex(currentIndex - 1);
    setCurrentQuestion(checklistResponseForm[currentIndex - 1]);
    console.log(currentIndex - 1);
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const isInspectValidAsPlan = (noInspecDone, plan) => {
    let noOfLimit = inspectionPlan[plan] && inspectionPlan[plan].maxAllowed;
    if (!noInspecDone) {
      noInspecDone = 0;
    }
    if (!noOfLimit) {
      return false;
    } else if (noOfLimit > noInspecDone) {
      return true;
    } else {
      return false;
    }
  };

  const getProgressBarPercent = () => {
    let percent = (currentIndex / checklistResponseForm.length) * 100;
    return `${percent}%`;
  };

  const getQuestionTypeComponent = (data, { handleSubmitAnswer }) => {
    if (data?.isMeterReading) {
      return (
        <MeterReadingInput
          data={checklistResponseForm}
          showSelection={lastAnsweredQuestion > currentIndex}
          currentIndex={currentIndex}
          setChecklistResponseForm={setChecklistResponseForm}
          handleSubmitAnswer={handleSubmitAnswer}
          uploadImage={uploadImage}
          deleteImage={deleteImage}
          deleteImageLoading={deleteImageLoading}
          uploadImageLoading={uploadImageLoading}
          assetId={assetId}
          elapsedTime={elapsedTime}
        />
      );
    } else if (data?.answers == "DropDown") {
      return (
        <ButtonInput
          data={checklistResponseForm}
          showSelection={lastAnsweredQuestion >= currentIndex}
          currentIndex={currentIndex}
          setChecklistResponseForm={setChecklistResponseForm}
          handleSubmitAnswer={handleSubmitAnswer}
          uploadImage={uploadImage}
          deleteImage={deleteImage}
          deleteImageLoading={deleteImageLoading}
          uploadImageLoading={uploadImageLoading}
          elapsedTime={elapsedTime}
        />
      );
    } else if (data?.answers == "Text") {
      return (
        <TextInput
          data={checklistResponseForm}
          showSelection={lastAnsweredQuestion >= currentIndex}
          currentIndex={currentIndex}
          setChecklistResponseForm={setChecklistResponseForm}
          handleSubmitAnswer={handleSubmitAnswer}
          uploadImage={uploadImage}
          deleteImage={deleteImage}
          deleteImageLoading={deleteImageLoading}
          uploadImageLoading={uploadImageLoading}
          elapsedTime={elapsedTime}
        />
      );
    } else if (data?.answers == "Numeric") {
      return (
        <NumericInput
          data={checklistResponseForm}
          showSelection={lastAnsweredQuestion >= currentIndex}
          currentIndex={currentIndex}
          setChecklistResponseForm={setChecklistResponseForm}
          handleSubmitAnswer={handleSubmitAnswer}
          uploadImage={uploadImage}
          deleteImage={deleteImage}
          deleteImageLoading={deleteImageLoading}
          uploadImageLoading={uploadImageLoading}
          elapsedTime={elapsedTime}
        />
      );
    }
  };

  const resultAfterSubmit = (resultStatus) => {
    setInspectionPass(resultStatus);
    setFinalReview(false);
    setResultPage(true);
  };

  const onSubmit = () => {
    let newResponse = {};
    checklistResponseForm.map((data) => {
      let currentQuestion = newResponse[data.sectionId];
      if (currentQuestion) {
        newResponse[data.sectionId].push(data);
      } else {
        newResponse[data.sectionId] = [data];
      }
    });
    let sectionResponse = [];
    Object.keys(newResponse).map((key) => {
      let newSection = newResponse[key];
      let newQuestions = [];
      newSection?.map((ques) => {
        newQuestions.push({
          failedResponse: ques.failedResponse,
          isfailedResponse: ques.isFailedResponse,
          question: ques.question,
          questionId: ques.id,
          response: ques.response,
          responseId: ques.responseTypeId,
          responseMedia: ques.responseMedia,
          sectionId: ques.sectionId,

          responseType: "Text",
          responseText: "Text",
          questionResponseTime: ques?.questionResponseTime,
          answerResponseTime: ques?.answerResponseTime,
          note: ques?.note || null,
        });
      });
      sectionResponse.push({
        sectionId: newSection[0]?.sectionId,
        sectionName: newSection[0]?.sectionName,
        sectionQuestionResponseTimeStatus: newSection[0]?.sectionQuestionResponseTimeStatus || false,
        sectionQuestionResponseTime: newSection[0]?.sectionQuestionResponseTime || 60,
        questions: [...newQuestions],
      });
    });

    const finalInspectionResponse = {
      accountId: accountId,
      inspectorId: inspectorId,
      assetId: assetId,
      checklistId: checklistId,
      // assetTypeId: ""
      isResponseTimeRecorded: true,
      response: sectionResponse,
    };

    postInspectionResponse(finalInspectionResponse, (resultStatus) => {
      resultAfterSubmit(resultStatus);
      handleSubmitButtonClick();
    });
  };

  const reviewResponse = () => {
    setFinalReview(false);
    setResultPage(false);
  };

  useEffect(() => {
    setOpenMessagePopup(true);
  }, [sectionId]);

  useEffect(() => {
    if (!accountId) {
      return;
    }
    setIsInspectionValid(true);
    // setInspectionCountLoading(true);
    // apiCall(`/checklist/inspectionCount/${accountId}`).then((data) => {
    //     setInspectionCountLoading(false);
    //     setIsInspectionValid(isInspectValidAsPlan(data?.data?.count, data?.data?.plan));
    // });
  }, [accountId, currentChecklist]);

  useEffect(() => {
    console.log("Current Question : ", currentQuestion);
    startTimer(currentQuestion?.questionResponseTime);
  }, [currentQuestion]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="bg-white fixed mt-24 inset-0 z-10 overflow-y-scroll"
          onClose={() => {}}
        >
          {openMessagePopup && (
            <MessagePopup
              data={currentQuestion}
              onClick={() => {
                setOpenMessagePopup(false);
                startTimer(currentQuestion?.questionResponseTime);
              }}
            />
          )}
          <div className="min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="modal-height-width inline-block pl-2 pr-2  pb-6 text-left align-middle transition-all transform bg-white">
                {!finalReview && !resultPage && (
                  <>
                    <div className="bg-[#b9d3f1] h-1 mb-2">
                      <div
                        className="bg-[#0D5FBE] h-1"
                        style={{ width: getProgressBarPercent() }}
                      ></div>
                    </div>
                    <div className="flex items-center">
                      <div
                        style={{ cursor: "pointer" }}
                        className="mr-1"
                        onClick={() => setPrevious()}
                      >
                        <ArrowIcon
                          className={`h-8 rotate-180 ${
                            currentIndex !== 0
                              ? "icon-color-black"
                              : "icon-color-default"
                          }`}
                        />
                      </div>
                      <div className=" modal-height-width">
                        <Dialog.Title as="h3">
                          <div class="w-full flex justify-center mt-4">
                            <span className="mr-2">
                              <span className="py-1 px-2 text-sm  leading-6 flex justify-center bg-color-grey uppercase  rounded-md text-black-text">
                                {currentQuestion?.message}
                              </span>
                            </span>
                            {currentQuestion?.sectionQuestionResponseTimeStatus && (
                              <span class="flex items-center">
                                <span className="py-1 px-2 text-sm  leading-6 flex justify-center bg-color-grey rounded-md text-black-text ">
                                  <span> {formatTime(timeLeft)}</span>
                                  {/* <p className="text-xl mt-2">Time Left: {timeLeft}s</p> */}
                                  {/* <p className="text-xl">Elapsed Time: {elapsedTime}s</p> */}
                                </span>
                              </span>
                            )}
                          </div>
                        </Dialog.Title>

                        <div style={{ marginTop: "2vh" }}>
                          
                          { currentQuestion && getQuestionTypeComponent(currentQuestion, {
                            handleSubmitAnswer: handleSubmitAnswer,
                          })}
                        </div>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="ml-1"
                        onClick={() => setNext()}
                      >
                        <ArrowIcon
                          className={`h-8  ${
                            currentIndex !== checklistResponseForm.length - 1
                              ? "icon-color-black"
                              : "icon-color-default"
                          }`}
                        />
                      </div>
                    </div>
                  </>
                )}
                {finalReview && (
                  <ReviewInspection
                    postInspectionRepsonseLoading={
                      postInspectionRepsonseLoading
                    }
                    onSubmit={onSubmit}
                    reviewResponse={reviewResponse}
                  />
                )}
                {resultPage && (
                  <InspectionResult
                    logoutUser={logoutUser}
                    inspectionPass={inspectionPass}
                    userFullName={userFullName}
                    setChecklistResponseForm={setChecklistResponseForm}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
