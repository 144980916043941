import { useEffect } from 'react';
import OTPInput from "otp-input-react";
import { Fragment, useState } from 'react';
import MultiplePhotoUpload from '../Input/FileUpload.component';
import { apiCall } from "../../../utils/apiCall";


export default function MeterReading({
    data,
    currentIndex,
    setChecklistResponseForm,
    handleSubmitAnswer,
    showSelection,
    uploadImage,
    deleteImage,
    deleteImageLoading,
    uploadImageLoading,
    assetId,
    elapsedTime
}) {
    const [meterReading, setMeterReading] = useState("");
    const [currentResponse, setCurrentResponse] = useState({});
    const [meterReadingValidation, setMeterReadingValidation] = useState('');
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setCurrentResponse(data[currentIndex]);
        setMeterReading(data[currentIndex]?.response);
        setShowError(false);
    }, [currentIndex]);

    const isMeterReadingValid = async (assetId, meterReading) => {
        return await apiCall(`/checklist/validateMeterReading/${assetId}/${meterReading}`)
    }

    const onClickSubmit = () => {
        setShowError(true);
        let isValid = false;
        if (!meterReading) {
            setMeterReadingValidation('Meter reading is required');
            return;
        }
        if (!(/^\d+$/.test(meterReading))) {
            setMeterReadingValidation('Please enter valid number');
            return;
        }
        let response = isMeterReadingValid(assetId, meterReading);
        response.then(res => {
            console.log(res.data, '----');
            isValid = res.data.success;
            if (!isValid) {
                setMeterReadingValidation('Meter reading should be greater than previous one');
                return;
            }
            data[currentIndex] = {
                ...currentResponse,
                response: meterReading,
                answerResponseTime: elapsedTime
            }
            handleSubmitAnswer();
        })
    };

    useEffect(() => {
        if (!meterReading) {
            setMeterReadingValidation('Meter reading is required');
            return;
        }
        if (!(/^\d+$/.test(meterReading))) {
            setMeterReadingValidation('Please enter valid number');
            return;
        }
        setMeterReadingValidation('');
    }, [meterReading]);

    const onClickNA = () => {
        data[currentIndex] = {
            ...currentResponse,
            response: 'NA',
            answerResponseTime: elapsedTime
        }
        //setChecklistResponseForm(data);
        handleSubmitAnswer();
    };

    return (
        <>
            <div className="">
                <div className='grid content-center'>
                    <div className="mt-2 flex justify-center overflow-y-scroll h-14 border-[#F2F2F2] border" style={{ height: '20vh' }}>
                        <p className="w-full font-sans p-1  text-xl rounded text-black-text flex justify-left">
                            {currentResponse?.question}
                        </p>
                    </div>
                    <br />
                    <div className='flex justify-center'>
                        <OTPInput className="otp-input flex flex-wrap font-kanit font-thin" inputClassName="border border-gray-700" value={meterReading} onChange={setMeterReading} autoFocus OTPLength={5} otpType="number" disabled={false} />
                    </div>
                    {showError && meterReadingValidation && <div className="text-color-red flex justify-center font-sans text-md">{meterReadingValidation}</div>}
                </div>

                <div className='flex items-center justify-center  pt-5'>
                    <div className='flex-1'>
                        <MultiplePhotoUpload
                            deleteImageLoading={deleteImageLoading}
                            uploadImageLoading={uploadImageLoading}
                            uploadImage={uploadImage}
                            deleteImage={deleteImage}
                            currentResponse={currentResponse}
                            setCurrentResponse={setCurrentResponse}
                        />
                    </div>
                    <div className='flex-1'>
                        <div className='w-full flex  justify-center'>
                            <button
                                type="button"
                                onClick={onClickSubmit}
                                className={`${(showSelection && meterReading !== 'NA') ? 'button-green-selected' : 'button-green'} font-kanit text-xl`}
                            >
                                SUBMIT
                            </button>
                        </div>
                        <div className='w-full flex  justify-center'>
                            <button
                                type="button"
                                onClick={onClickNA}
                                className={`${(showSelection && meterReading === 'NA') ? 'button-gray-selected' : 'button-gray'} mt-4 font-kanit text-xl`}
                            >
                                NA
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
