import { Dialog, Transition } from "@headlessui/react";

export default function MessagePopup({ data, onClick }) {
  return (
    <>
      <div className="fixed inset-0 z-10 min-h-screen min-w-screen flex items-center justify-center bg-white bg-opacity-90">
        <div className="">
          <div className="flex items-center justify-center">
            <Dialog.Title
              as="h3"
              className="p-2 text-xl font-sans text-black-text leading-6 flex justify-center rounded-md text-gray-900"
            >
              {data?.message}
            </Dialog.Title>
          </div>
          <br />
          <div className="flex items-center justify-center">
            <button
              type="button"
              onClick={onClick}
              className="text-white bg-primary-background pt-4 pb-4 rounded-md font-kanit w-full flex items-center justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 w-[200px]"
            >
              CONTINUE
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
