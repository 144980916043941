import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { ReactComponent as SampleProfileAvatar } from '../../assets/avatar.svg'
import { s3UserImageUrl } from '../../config'
import { Trans } from 'react-i18next';


const UserProfileCardView = ({ userDetails, profile }) => {

  const [isImageLoadError, setIsImageLoadError] = useState(false)

  return (
    <>
      <div className='mt-20'>
        <div className='flex justify-center'>
          {
            profile?.updatedProfileImg ? <>
              <img className='rounded-full w-32 h-32' src={profile?.updatedProfileImg} alt={"profile"} />
            </> : <>
              {
                userDetails?.userData?.imgData ?
                  !isImageLoadError && <img className='rounded-full w-32 h-32' src={`${s3UserImageUrl}/${userDetails?.userData?.imgData}`} alt={"user profile"} onError={() => setIsImageLoadError(true)} onLoad={() => setIsImageLoadError(false)} /> :
                  <SampleProfileAvatar className="w-32" fill='#3c3c3c' />
              }
            </>
          }

          {
            isImageLoadError && <SampleProfileAvatar className="w-32" fill='#3c3c3c' />
          }
        </div>
        <div className='mt-6 mb-10'>
          <p className="text-2xl text-black mb-2">{profile?.fullName || "NA"}</p>
          <p className="text-xl text-slate-500 capitalize mb-10">{userDetails?.userData?.permissions}</p>
          <p>
            <label className="block text-left rounded-md">
              <span className=" block text-sm font-medium text-black-text bg-background-gray pl-2 pt-2 pr-2 opacity-80"><Trans>Phone</Trans></span>
              <input type="tel" value={profile?.phoneNo || "+1 (XXX) XXXX XXXX"} className="peer bg-background-gray w-full focus:outline-none p-2 text-black font-noraml disabled:text-black-text" disabled />
            </label>
          </p>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  userDetails: state?.auth?.userDetails,
  profile: state.profile
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileCardView);