import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InspectionModal from "./Modal.component";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { ReactComponent as SelectIcon } from "../../assets/ArrowRight.svg";

// redux
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import {
  uploadImage,
  deleteImage,
  getChecklistById,
  postInspectionResponse,
} from "../../redux/inspection/actions";
import { getAssetChecklists } from "../../redux/assets/actions";
import { setInspectionStatus } from "../../redux/inspection/actions";
import { logoutUser } from "../../redux/auth/actions";
import { s3AssetImageUrl, s3UserImageUrl } from "../../config";

function Inspection({
  accountId,
  userFullName,
  inspectorId,
  // assetId,
  postInspectionRepsonseLoading,
  uploadImage,
  deleteImage,
  postInspectionResponse,
  userData,
  getAssetChecklists,
  assetChecklists,
  deleteImageLoading,
  uploadImageLoading,
  getChecklistLoading,
  getChecklistById,
  currentChecklist,
  inspectionStatus,
  setInspectionStatus,
  logoutUser,
  assetDetails,
}) {
  let { assetId } = useParams();
  const [selectedChecklist, setSelectedChecklist] = useState("");
  const [currentLocalChecklist, setCurrentLocalChecklist] = useState([]);

  useEffect(() => {
    getAssetChecklists({ assetId, accountId });
  }, []);

  useEffect(() => {
    if (!assetChecklists?.[0]) {
      return;
    }
    setSelectedChecklist(assetChecklists[0]);
  }, [assetChecklists]);

  useEffect(() => {
    if (!selectedChecklist) {
      return;
    }
    getChecklistById(selectedChecklist?.checklistId?._id);
  }, [selectedChecklist]);

  useEffect(() => {
    let newChecklistResponse = [];
    console.log(currentChecklist, "----------->>||>>>");
    currentChecklist?.sections?.map((section) => {
      section?.questions?.map((question) => {
        newChecklistResponse.push({
          message: section?.notificationMessage,
          notification: section?.sectionStartNotification,
          question: question?.questionText,
          answers: question?.responseTypeId?.controlType,
          isFailedResponse: question?.isQuestionMarkAsChecklistFail,
          failedResponse: question?.failedResponse,
          id: question?._id,
          sectionId: section?._id,
          sectionName: section?.sectionName,
          isMeterReading:
            question?.responseTypeId?.responseText === "Meter Reading",
          responseTypeId: question?.responseTypeId?._id,
          sectionQuestionResponseTimeStatus:
            section?.sectionQuestionResponseTimeStatus,
          questionResponseTime: question?.questionResponseTime,
        });
      });
    });
    setCurrentLocalChecklist([...newChecklistResponse]);
    console.log(newChecklistResponse, "----------->>>>>");
  }, [currentChecklist]);

  const handleStartInspectionButtonClick = () => {
    setInspectionStatus(true);
  };

  const handleSubmitButtonClick = () => {
    setInspectionStatus(false);
  };

  useEffect(() => {
    handleStartInspectionButtonClick()
  }, [])
  return (
    <div style={{ height: "80vh" }}>
      <InspectionModal
        uploadImage={uploadImage}
        deleteImage={deleteImage}
        postInspectionResponse={postInspectionResponse}
        postInspectionRepsonseLoading={postInspectionRepsonseLoading}
        uploadImageLoading={uploadImageLoading}
        deleteImageLoading={deleteImageLoading}
        getChecklistLoading={getChecklistLoading}
        logoutUser={logoutUser}
        inspectData={{
          userFullName: userFullName,
          accountId: accountId,
          inspectorId: inspectorId,
          assetId: assetId,
          currentChecklist: currentLocalChecklist,
          checklistId: currentChecklist?._id,
        }}
        handleStartInspectionButtonClick={handleStartInspectionButtonClick}
        handleSubmitButtonClick={handleSubmitButtonClick}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  postInspectionRepsonseLoading: state.inspect.postInspectionRepsonseLoading,
  currentChecklist: state.inspect.checklist,
  inspectionStatus: state.inspect.inspectionStatus,
  getChecklistLoading: state.inspect.getChecklistLoading,
  deleteImageLoading: state.inspect.deleteImageLoading,
  uploadImageLoading: state.inspect.uploadImageLoading,
  accountId: state.auth.userDetails.accountId,
  userFullName: state.auth.userDetails?.userData?.fullName,
  userData: state.auth.userDetails.userData,
  inspectorId: state.auth.userDetails?.userData?._id,
  assetChecklists: state.assets.assetChecklists,
  assetDetails: state.assets.assetDetails,
  inspectionPlanDetail: state.auth.userDetails.plan,
});

const mapDispatchToProps = (dispatch) => ({
  uploadImage: (data, callbackAfterUpload) =>
    dispatch(uploadImage(data, callbackAfterUpload)),
  deleteImage: (data, callbackAfterDelete) =>
    dispatch(deleteImage(data, callbackAfterDelete)),
  postInspectionResponse: (data, callbackAfterInspect) =>
    dispatch(postInspectionResponse(data, callbackAfterInspect)),
  getAssetChecklists: (data) => dispatch(getAssetChecklists(data)),
  getChecklistById: (checklistId) => dispatch(getChecklistById(checklistId)),
  setInspectionStatus: (data) => dispatch(setInspectionStatus(data)),
  logoutUser: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inspection);
