import { assetsTypes } from "./types";
import axios from "axios";
import { baseUrl } from "../../../src/config";

// ASSET TYPE LIST
const setAssetTypeListLoading = () => ({
  type: assetsTypes.ASSET_TYPE_LIST_LOADING,
});

const setAssetTypeListSuccess = (data) => ({
  type: assetsTypes.ASSET_TYPE_LIST_SUCCESS,
  data,
});

const setAssetTypeListError = (err) => ({
  type: assetsTypes.ASSET_TYPE_LIST_ERROR,
  data: err,
});

export const resetAssetTypeListState = () => ({
  type: assetsTypes.RESET_ASSET_TYPE_LIST_STATE,
});

export const getAssetTypeList = (data) => {
  return (dispatch, getState) => {
    let state = getState();
    let token = state?.auth?.userDetails?.token;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };

    let accountId = state?.auth?.userDetails?.accountId;

    dispatch(setAssetTypeListLoading());

    axios
      .get(`${baseUrl}/assetType/filter/assetTypeList/${accountId}`, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        dispatch(setAssetTypeListSuccess(res?.data?.data));
      })
      .catch((err) => {
        dispatch(setAssetTypeListError());
      });
  };
};

// ASSET LIST
const setAssetListLoading = () => ({
  type: assetsTypes.ASSET_LIST_LOADING,
});

const setAssetListSuccess = (data) => ({
  type: assetsTypes.ASSET_LIST_SUCCESS,
  data,
});

const setAssetListError = (err) => ({
  type: assetsTypes.ASSET_LIST_ERROR,
  data: err,
});

export const resetAssetListState = () => ({
  type: assetsTypes.RESET_ASSET_LIST_STATE,
});

export const getAssetList = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state?.auth?.userDetails?.token;
    const headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };
    const siteId =
      state?.auth?.userDetails?.userData?.authorizedSites?.[0]?._id || 0;

    dispatch(setAssetListLoading());

    axios
      .get(`${baseUrl}/asset/getallassets/${siteId}`, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        dispatch(setAssetListSuccess(res?.data?.data));
      })
      .catch((err) => {
        dispatch(setAssetListError());
      });
  };
};

// GET ASSET CHECKLISTS
const setAssetChecklistsLoading = () => ({
  type: assetsTypes.GET_ASSET_CHECKLIST_LOADING,
});

const setAssetChecklistsSuccess = (data) => ({
  type: assetsTypes.GET_ASSET_CHECKLIST_SUCCESS,
  data,
});

const setAssetChecklistsError = (err) => ({
  type: assetsTypes.GET_ASSET_CHECKLIST_ERROR,
  data: err,
});

export const getAssetChecklists = ({ assetId, accountId }) => {
  return (dispatch, getState) => {
    let state = getState();

    let token = state?.auth?.userDetails?.token;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };

    dispatch(setAssetChecklistsLoading());
    axios
      .get(`${baseUrl}/asset/listcheklist/${accountId}/${assetId}`, {
        headers: headers,
      })
      .then((res) => {
        console.log("CHECKLIST", res?.data?.data?.[0]);
        dispatch(setAssetChecklistsSuccess(res?.data?.data?.[0]));
      })
      .catch((err) => {
        dispatch(setAssetChecklistsError());
      });
  };
};
