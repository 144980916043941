import React from 'react'
import { useNavigate } from "react-router-dom";
import QrReader from "react-qr-reader";


function ScanAsset() {

    let navigate = useNavigate();
    
    const handleBackButton = () => {
        navigate("/asset-list", { replace: true })
    }

    const handleAssetQRCodeSuccess = (data) => {
        console.log(data)
        let assetID = data?.split?.("/")[data?.split?.("/").length - 1];
        console.log("ASSET ID", assetID)
        if (assetID) {
            navigate(`/inspect/${assetID}`, { replace: true })
        }
    }

    return (
        <>
            <div className='w-full bg-secondary-background p-3 text-white font-base text-lg flex items-center'>
                <span className='mr-1 cursor-pointer' onClick={() => handleBackButton()}>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
                    </svg>
                </span>
                <p>Scan Asset</p>
            </div>
            <div className='mx-auto w-[90%] sm:w-6/12 md:w-1/2 lg:w-3/12 text-center font-sans'>
                <div className='mx-auto cursor-pointer mt-10'>
                    <div className='w-full'>
                        <QrReader
                            delay={2000}
                            onError={() => { }}
                            onScan={(result) => { handleAssetQRCodeSuccess(result) }}
                        />
                    </div>
                    <p className='text-xl text-black-text mt-6'>Scan Asset QR</p>
                </div>
            </div>
        </>
    )
}

export default ScanAsset